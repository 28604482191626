import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserType } from '@enum';
import { ClientListItem } from '@interfaces';
import {
  lettersOnlyValidator,
  noSpacesEmailValidator,
  numebrsOnlyValidator,
} from '@validators';
import { UserUpdateDto } from 'app/core/dtos/user-update.dto';
import { UserService } from 'app/features/users/services/user.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-edit-client-modal',
  templateUrl: './edit-client-modal.component.html',
  styleUrl: './edit-client-modal.component.scss',
})
export class EditClientModalComponent {
  @Input() selectedClient!: ClientListItem;
  @Input() modalRef!: BsModalRef;

  @Output() updated = new EventEmitter<ClientListItem>();

  submitted: boolean = false;
  editUserForm: FormGroup;
  isVisible: boolean = false;
  userDto: UserUpdateDto;

  constructor(
    private userService: UserService,
    private fb: FormBuilder,
    private toastr: ToastrService
  ) {
    this.userDto = {
      complete_name: '',
      email: '',
      id: 0,
      last_name: '',
      name: '',
      number: 0,
      phone: '',
      type_user: UserType.USER,
      municipality: '',
      state: '',
    };
    this.editUserForm = this.fb.group({
      number: ['', [Validators.required]],
      email: [
        '',
        [
          Validators.required,
          Validators.maxLength(50),
          Validators.minLength(2),
          noSpacesEmailValidator(),
        ],
      ],
      complete_name: [
        '',
        [
          Validators.required,
          Validators.maxLength(50),
          Validators.minLength(2),
          lettersOnlyValidator(),
        ],
      ],
      phone: [
        '',
        [
          numebrsOnlyValidator(),
          Validators.maxLength(10),
          Validators.minLength(10),
        ],
      ],
      municipality: [
        '',
        [
          Validators.required,
          Validators.maxLength(30),
          Validators.minLength(2),
          lettersOnlyValidator(),
        ],
      ],
      state: [[Validators.required]],
    });
  }

  ngOnInit() {
    this.editUserForm.setValue({
      email: this.selectedClient.email,
      complete_name: this.selectedClient.complete_name,
      number: this.selectedClient.number,
      phone: this.selectedClient.phone,
      municipality: this.selectedClient.municipality,
      state: this.selectedClient.state,
    });
    this.editUserForm.get('number')?.disable();
  }

  close = (): void => {
    if (this.modalRef) {
      this.modalRef.hide();
    }
  };

  public onSubmit = (): void => {
    this.submitted = true;
    if (this.editUserForm.valid) {
      this.setUserDto(this.editUserForm.value, this.selectedClient);
      this.userService
        .updateUser(this.userDto, this.userDto.id)
        .subscribe(({ data, code }) => {
          if (code === 500) {
            this.submitted = false;
            return this.toastr.error(
              'No fue posible actualizar la información del usuario',
              '¡Error!'
            );
          }
          this.updated.emit(data);
          this.toastr.success(`¡Información actualizada!`, '¡Éxito!');
          this.close();
          return;
        });
    }
  };

  get numberErrors() {
    const numberControl = this.editUserForm.get('number');
    if (numberControl?.hasError('required')) {
      return 'Campo requerido';
    } else if (numberControl?.hasError('maxlength')) {
      return 'Debe contener máximo 5 caracteres.';
    }
    return '';
  }

  get completeNameErrors() {
    const nameControl = this.editUserForm.get('complete_name');
    if (nameControl?.hasError('required')) {
      return 'Campo requerido';
    } else if (nameControl?.hasError('lettersOnly')) {
      return 'Solo se permiten letras';
    } else if (nameControl?.hasError('maxlength')) {
      return 'Debe tener máximo 50 caracteres';
    } else if (nameControl?.hasError('minlength')) {
      return 'Debe tener mínimo 2 caracteres';
    }
    return '';
  }

  get municipalityErrors() {
    const nameControl = this.editUserForm.get('municipality');
    if (nameControl?.hasError('required')) {
      return 'Campo requerido';
    } else if (nameControl?.hasError('lettersOnly')) {
      return 'Solo se permiten letras';
    } else if (nameControl?.hasError('maxlength')) {
      return 'Debe tener máximo 30 caracteres';
    } else if (nameControl?.hasError('minlength')) {
      return 'Debe tener mínimo 2 caracteres';
    }
    return '';
  }

  get phoneErrors() {
    const nameControl = this.editUserForm.get('phone');
    if (nameControl?.hasError('numbersOnly')) {
      return 'Solo se permiten letras';
    } else if (
      nameControl?.hasError('maxlength') ||
      nameControl?.hasError('minlength')
    ) {
      return 'Debe tener exactamente 10 caracteres';
    }
    return '';
  }

  get emailErrors() {
    const emailControl = this.editUserForm.get('email');
    if (emailControl?.hasError('required')) {
      return 'Campo requerido';
    } else if (emailControl?.hasError('noSpacesEmail')) {
      return 'Formato inválido';
    } else if (emailControl?.hasError('maxlength')) {
      return 'Debe tener máximo 50 caracteres';
    } else if (emailControl?.hasError('minlength')) {
      return 'Debe tener mínimo 2 caracteres';
    }
    return '';
  }

  get stateErrors() {
    const emailControl = this.editUserForm.get('state');
    if (emailControl?.hasError('required')) {
      return 'Campo requerido';
    }
    return '';
  }

  onInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    const value = input.value;
    const trimmedValue = value.trimStart();
    this.editUserForm
      .get('complete_name')
      ?.setValue(trimmedValue, { emitEvent: false });
  }

  onPaste(event: ClipboardEvent): void {
    const pastedData = event.clipboardData?.getData('text') || '';
    const trimmedData = pastedData.trimStart();
    event.preventDefault();
    const input = event.target as HTMLInputElement;
    const cursorPosition = input.selectionStart ?? 0;
    const currentValue = input.value;
    const newValue =
      currentValue.slice(0, cursorPosition) +
      trimmedData +
      currentValue.slice(input.selectionEnd ?? cursorPosition);
    input.value = newValue.trimStart();
    this.editUserForm.patchValue({ complete_name: input.value });
  }

  noChangesUserInfo = (): boolean => {
    const {
      complete_name: completeNameForm,
      email: emailForm,
      number: numberForm,
      phone: phoneForm,
      municipality: municipalityForm,
      state: stateForm,
    } = this.editUserForm.value;

    const { complete_name, email, number, phone, municipality, state } =
      this.selectedClient;
    return (
      complete_name === completeNameForm &&
      email === emailForm &&
      number === numberForm &&
      phone === phoneForm &&
      municipality === municipalityForm &&
      state === stateForm
    );
  };

  isDisabled = (): boolean => {
    return (
      this.noChangesUserInfo() || this.editUserForm.invalid || this.submitted
    );
  };

  setUserDto = (userForm: any, user: ClientListItem): void => {
    this.userDto = {
      complete_name: userForm.complete_name,
      email: userForm.email,
      id: user.id,
      last_name: user.last_name,
      name: user.name,
      number: userForm.number,
      phone: userForm.phone,
      type_user: UserType.CLIENTE,
      municipality: userForm.municipality,
      state: userForm.state,
    };
  };
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserType } from '@enum';
import { UserListItem } from '@interfaces';
import { lettersOnlyValidator, noSpacesEmailValidator } from '@validators';
import { UserUpdateDto } from 'app/core/dtos/user-update.dto';
import { UserService } from 'app/features/users/services/user.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-edit-user-modal',
  templateUrl: './edit-user-modal.component.html',
  styleUrl: './edit-user-modal.component.scss',
})
export class EditUserModalComponent {
  @Input() user!: UserListItem;
  @Input() modalRef!: BsModalRef;

  @Output() userUpdated = new EventEmitter<UserListItem>();

  submitted: boolean = false;
  editUserForm: FormGroup;
  isVisible: boolean = false;
  userDto: UserUpdateDto;

  constructor(
    private userService: UserService,
    private fb: FormBuilder,
    private toastr: ToastrService
  ) {
    this.userDto = {
      complete_name: '',
      email: '',
      id: 0,
      last_name: '',
      name: '',
      number: 0,
      phone: '',
      type_user: UserType.USER,
    };
    this.editUserForm = this.fb.group({
      number: ['', [Validators.required, Validators.maxLength(5)]],
      email: ['', [Validators.required, noSpacesEmailValidator()]],
      complete_name: [
        '',
        [
          Validators.required,
          Validators.maxLength(50),
          Validators.minLength(1),
          lettersOnlyValidator(),
        ],
      ],
      admin_role: ['', []],
      finance_role: ['', []],
    });
  }

  ngOnInit() {
    this.editUserForm.setValue({
      email: this.user.email,
      complete_name: this.user.complete_name,
      number: this.user.number,
      admin_role: this.user.admin_role ? '1' : 'null',
      finance_role: this.user.finance_role ? '1' : 'null',
    });
    this.onChanges();
  }

  close = (): void => {
    if (this.modalRef) {
      this.modalRef.hide();
    }
  };

  public onSubmit = (): void => {
    this.submitted = true;
    if (this.editUserForm.valid) {
      this.setUserDto(this.editUserForm.value, this.user);
      this.userService
        .updateUser(this.userDto, this.userDto.id)
        .subscribe(({ data, code }) => {
          if (code === 500) {
            this.submitted = false;
            return this.toastr.error(
              'No fue posible actualizar la información del usuario',
              '¡Error!'
            );
          }
          this.userUpdated.emit(data);
          this.toastr.success(`¡Información actualizada!`, '¡Éxito!');
          this.close();
          return;
        });
    }
  };

  get numberErrors() {
    const numberControl = this.editUserForm.get('number');
    if (numberControl?.hasError('required')) {
      return 'Campo requerido';
    } else if (numberControl?.hasError('maxlength')) {
      return 'Debe contener máximo 5 caracteres.';
    }
    return '';
  }

  get completeNameErrors() {
    const nameControl = this.editUserForm.get('complete_name');
    if (nameControl?.hasError('required')) {
      return 'Campo requerido';
    } else if (nameControl?.hasError('lettersOnly')) {
      return 'Solo se permiten letras';
    } else if (nameControl?.hasError('maxlength')) {
      return 'Debe tener máximo 50 caracteres';
    } else if (nameControl?.hasError('minlength')) {
      return 'Debe tener mínimo 1 caracter';
    }
    return '';
  }

  get emailErrors() {
    const emailControl = this.editUserForm.get('email');
    if (emailControl?.hasError('required')) {
      return 'Campo requerido';
    } else if (emailControl?.hasError('noSpacesEmail')) {
      return 'Formato inválido';
    }
    return '';
  }

  onInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    const value = input.value;
    const trimmedValue = value.trimStart();
    this.editUserForm
      .get('complete_name')
      ?.setValue(trimmedValue, { emitEvent: false });
  }

  onPaste(event: ClipboardEvent): void {
    const pastedData = event.clipboardData?.getData('text') || '';
    const trimmedData = pastedData.trimStart();
    event.preventDefault();
    const input = event.target as HTMLInputElement;
    const cursorPosition = input.selectionStart ?? 0;
    const currentValue = input.value;
    const newValue =
      currentValue.slice(0, cursorPosition) +
      trimmedData +
      currentValue.slice(input.selectionEnd ?? cursorPosition);
    input.value = newValue.trimStart();
    this.editUserForm.patchValue({ complete_name: input.value });
  }

  noChangesUserInfo = (): boolean => {
    const {
      complete_name: complete_name_form,
      email: emailForm,
      number: numberForm,
      admin_role: adminRoleForm,
      finance_role: financeRoleForm,
    } = this.editUserForm.value;

    const { complete_name, email, number, admin_role, finance_role } =
      this.user;
    return (
      complete_name === complete_name_form &&
      email === emailForm &&
      number === numberForm &&
      admin_role + '' === adminRoleForm &&
      finance_role + '' === financeRoleForm
    );
  };

  isDisabled = (): boolean => {
    return (
      this.noChangesUserInfo() || this.editUserForm.invalid || this.submitted
    );
  };

  setUserDto = (userForm: any, user: UserListItem): void => {
    this.userDto = {
      complete_name: userForm.complete_name,
      email: userForm.email,
      id: user.id,
      last_name: user.last_name,
      name: user.name,
      number: userForm.number,
      phone: user.phone,
      admin_role: userForm.admin_role === '1' ? 1 : null,
      finance_role: userForm.finance_role === '1' ? 1 : null,
      call_center_role:
        userForm.admin_role === 'null' && userForm.finance_role === 'null'
          ? 1
          : null,
      type_user: UserType.USER,
    };
  };

  onChanges(): void {
    this.editUserForm.get('admin_role')?.valueChanges.subscribe((value) => {
      if (value === '1') {
        this.editUserForm.get('finance_role')?.setValue('null');
      }
    });

    this.editUserForm.get('finance_role')?.valueChanges.subscribe((value) => {
      if (value === '1') {
        this.editUserForm.get('admin_role')?.setValue('null');
      }
    });
  }
}

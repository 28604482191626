import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SURVEY_QUESTIONS } from '@constants';
import { OrderStatus, ProductType } from '@enum';
import { SurveyListItem } from '@interfaces';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-survey-details-modal',
  templateUrl: './survey-details-modal.component.html',
  styleUrl: './survey-details-modal.component.scss',
})
export class SurveyDetailsModalComponent {
  @Input() survey!: SurveyListItem;
  @Input() modalRef!: BsModalRef;

  @Output() updated = new EventEmitter<SurveyListItem>();

  productType = ProductType;
  questions = SURVEY_QUESTIONS;
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { OrderStatus, ProductType } from '@enum';
import { environment } from '@env/environment';
import { OrderListItem } from 'app/core/interfaces/order-list-item.interface';
import { FileService } from 'app/core/services/file.service';
import { OrderService } from 'app/features/orders/services/order.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-order-details-modal',
  templateUrl: './order-details-modal.component.html',
  styleUrl: './order-details-modal.component.scss',
})
export class OrderDetailsModalComponent {
  @Input() order!: OrderListItem;
  @Input() modalRef!: BsModalRef;

  @Output() closed = new EventEmitter<OrderListItem>();

  productType = ProductType;

  submitted: boolean = false;

  url: string = environment.baseUrl;

  constructor(
    private fileService: FileService,
    private orderService: OrderService,
    private toastr: ToastrService
  ) {}

  getAddress(): string {
    const { address } = this.order;
    return ` ${address.street}, ${address.number}${
      address.number_in ? ' ' + address.number_in : ''
    }, ${address.colony}, ${address.location}, ${address.state}`;
  }

  setStatusColor(estatus: string) {
    let cssClass: string = '';
    switch (estatus) {
      case OrderStatus.COMPLETED:
        cssClass = 'status-finished-bg';
        break;
      case OrderStatus.NEW:
        cssClass = 'status-new-bg';
        break;
      case OrderStatus.CANCELED:
        cssClass = 'status-canceled-bg';
        break;
      case OrderStatus.IN_PROGRESS:
        cssClass = 'status-inprocess-bg';
        break;
    }
    return cssClass;
  }

  dowloadFile() {
    const url = `${environment.storageRecordsUrl}${this.order.fiscal_data.record}`;
    this.fileService.downloadFile(url, this.order.fiscal_data.record);
  }

  openUploadModal() {
    this.closed.emit();
    if (this.modalRef) {
      this.modalRef.hide();
    }
  }

  sendBillNotification = async (): Promise<any> => {
    this.orderService
      .sendBillNotification(this.order.id, this.url)
      .subscribe(({ code, data }: any) => {
        if (code === 500) {
          return this.toastr.error(
            'No se pudo enviar la notificación',
            '¡Error!'
          );
        }
        this.toastr.success('¡Notificación enviada!', '¡Éxito!');
        this.closed.emit();
        if (this.modalRef) {
          this.modalRef.hide();
        }
        return;
      });
  };
}

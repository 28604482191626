import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { passwordContainsLetterAndNumber } from './password-contains-letters-and-numbers.validator';
import { conditionalRequiredValidator } from './conditional-required.validator';
import { noSpacesValidator } from './no-spaces.validator';

export function compositePasswordValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const conditionalRequired = conditionalRequiredValidator()(control);
    if (conditionalRequired) {
      const errors: ValidationErrors = {};

      if (!control.value) {
        return conditionalRequired;
      }

      const containsLetterAndNumberError =
        passwordContainsLetterAndNumber()(control);
      const noSpacesValidatorError = noSpacesValidator()(control);

      if (noSpacesValidatorError) {
        errors['noSpaces'] = true;
      }
      if (containsLetterAndNumberError) {
        errors['containsLetterAndNumber'] = true;
      }
      if (control.value.length < 8) {
        errors['minlength'] = true;
      }
      if (control.value.length > 20) {
        errors['maxlength'] = true;
      }

      return Object.keys(errors).length > 0 ? errors : null;
    }

    return null;
  };
}

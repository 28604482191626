import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ENDPOINT } from '@constants';
import { ExportType } from '@enum';
import { Price, PriceHistoryFilter } from '@interfaces';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PricingService {
  constructor(private http: HttpClient) {}

  create(prices: Price[]): Observable<any> {
    return this.http.post<any>(ENDPOINT.PRICES.STORE, prices).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  filtered({
    page,
    end_date,
    start_date,
    product,
  }: PriceHistoryFilter): Observable<any> {
    return this.http
      .get<any>(ENDPOINT.PRICES.PAGINATED(page, start_date, end_date, product))
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  export(type: ExportType): Observable<any> {
    return this.http.get<any>(ENDPOINT.PRICES.EXPORT(type)).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  latest(): Observable<any> {
    return this.http.get<any>(ENDPOINT.PRICES.LATEST).pipe(
      map((response: any) => {
        return response;
      })
    );
  }
}

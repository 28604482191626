<div class="modal-header d-flex justify-content-center border-0">
  <h4 class="modal-title text-color fw-bold mt-3">Agregar comentarios</h4>
</div>
<div class="modal-body text-center pt-0">
  <form [formGroup]="commentForm" (ngSubmit)="onSubmit()" class="w-100 pt-2">
    <div
      class="container d-flex flex-column justify-content-center align-items-center"
    >
      <div class="form-group w-75 me-1">
        <div class="form-floating">
          <textarea
            id="response"
            type="text"
            class="form-control"
            placeholder="Comentarios"
            formControlName="response"
          ></textarea>
          <label for="response">Comentarios</label>
        </div>
        <div class="text-danger pt-1">
          {{ commentErrors }}
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <button
        class="btn btn-primary bg-ligth-mode ps-5 mt-5 pt-2 pb-2 pe-5"
        type="submit"
        [disabled]="commentForm.invalid || submitted"
      >
        Confirmar
      </button>
    </div>
    <div class="d-flex justify-content-center"></div>
    <label class="clickable text-ligth-mode fw-bolder mt-3" (click)="close()">
      Cancelar
    </label>
  </form>
</div>

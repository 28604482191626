import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
  noSpacesValidator,
  passwordContainsLetterAndNumber,
} from '@validators';
import { AuthService } from 'app/core/auth/auth.service';
import { ThemeService } from 'app/core/services/theme.service';
import { matchPasswordValidator } from 'app/core/validators/match-password.validator';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrl: './change-password.component.scss',
})
export class ChangePasswordComponent {
  submitted: boolean = false;
  changePasswordForm: FormGroup;
  isVisible: boolean = false;
  isVisibleConfirm: boolean = false;
  isDarkMode: boolean = false;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private authService: AuthService,
    private toastr: ToastrService,
    private themeService: ThemeService
  ) {
    this.changePasswordForm = this.fb.group(
      {
        password: [
          '',
          [
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(20),
            passwordContainsLetterAndNumber(),
            noSpacesValidator(),
          ],
        ],
        confirmPassword: ['', [Validators.required]],
      },
      {
        validators: matchPasswordValidator('password', 'confirmPassword'),
      }
    );
  }

  toggleTheme(isDarkMode: boolean) {
    this.themeService.setDarkTheme(isDarkMode);
  }

  ngOnInit() {
    this.themeService.darkTheme$.subscribe((isDark) => {
      this.isDarkMode = isDark;
    });

    const savedTheme = localStorage.getItem('$theme');
    if (savedTheme) {
      this.toggleTheme(savedTheme === 'dark');
    }
  }

  public onSubmit = async (): Promise<void> => {
    this.submitted = true;
    if (this.changePasswordForm.valid) {
      const password: string = this.changePasswordForm.value.password;
      this.authService.changePassword(password).subscribe(({ code }) => {
        if (code !== 200) {
          this.submitted = false;
          this.router.navigate(['/recover-password']);
          return this.toastr.error(
            'No se pudo actualizar la contraseña',
            '¡Error!'
          );
        }
        this.router.navigate(['/login']);
        this.authService.clearToken();
        return this.toastr.success(
          `¡Contraseña actualizada con éxito, serás redirigido al Login!`,
          '¡Éxito!'
        );
      });
    }
  };

  get passwordErrors() {
    const passwordControl = this.changePasswordForm.get('password');
    if (passwordControl?.hasError('required')) {
      return 'Contraseña requerida.';
    } else if (passwordControl?.hasError('minlength')) {
      return 'Debe tener mínimo 8 caracteres.';
    } else if (passwordControl?.hasError('maxlength')) {
      return 'Debe tener máximo 20 caracteres.';
    } else if (passwordControl?.hasError('containsLetterAndNumber')) {
      return 'Debe contener letras y números.';
    } else if (passwordControl?.hasError('noSpaces')) {
      return 'No puede contener espacios.';
    }
    return '';
  }

  get confirmPasswordErrors() {
    const confirmPasswordControl =
      this.changePasswordForm.get('confirmPassword');
    if (confirmPasswordControl?.hasError('required')) {
      return 'Confirmar contraseña requerida.';
    } else if (confirmPasswordControl?.hasError('matchPassword')) {
      return 'Las contraseñas no coinciden.';
    }
    return '';
  }

  changePasswordVisibility = (useIsVisible?: boolean): void => {
    if (useIsVisible) {
      this.isVisible = !this.isVisible;
      return;
    }
    this.isVisibleConfirm = !this.isVisibleConfirm;
  };
}

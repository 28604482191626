import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from '../auth/auth.service';

@Directive({
  selector: '[appHasRole]',
})
export class HasRoleDirective {
  private currentRoles: string[] = [];

  constructor(
    private authService: AuthService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {}

  @Input()
  set appHasRole(roles: string[]) {
    this.currentRoles = roles;
    this.updateView();
  }

  private updateView() {
    const hasAccess = this.currentRoles.some((role) =>
      this.authService.hasRole(role)
    );

    if (hasAccess) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}

<div class="container-fluid ps-0 pe-3 mt-0 pt-4 w-100 app-bg">
  <div class="card shadow border-0 card-bg">
    <div class="card-body">
      <form [formGroup]="pricingForm" (ngSubmit)="onSubmit()">
        <div class="row d-flex ms-5 me-5">
          <div class="col-6 d-flex justify-content-end pe-5">
            <div class="form-group w-75">
              <label
                for="cilinder20"
                class="fw-bolder mb-2 ms-1 text-ligth-mode"
                >Cilindro de 20 Kg</label
              >
              <input
                id="cilinder20"
                type="text"
                class="form-control w-100 border-ligth-mode"
                placeholder="00.00"
                formControlName="amount20"
                (input)="validateInput($event)"
                (paste)="handlePaste($event)"
              />
              <div
                *ngIf="
                  pricingForm.get('amount20')?.dirty ||
                  pricingForm.get('amount20')?.touched
                "
              >
                <div class="text-danger m-2">
                  {{ amount20Errors }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-6 ps-5">
            <div class="form-group w-75">
              <label
                for="cilinder30"
                class="fw-bolder mb-2 ms-1 text-ligth-mode"
                >Cilindro de 30 Kg</label
              >
              <input
                id="cilinder30"
                type="text"
                class="form-control w-100 border-ligth-mode"
                placeholder="00.00"
                formControlName="amount30"
                (input)="validateInput($event)"
                (paste)="handlePaste($event)"
              />
              <div
                *ngIf="
                  pricingForm.get('amount30')?.dirty ||
                  pricingForm.get('amount30')?.touched
                "
              >
                <div class="text-danger m-2">
                  {{ amount30Errors }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center mt-5">
          <button
            class="btn btn-solid-2-bg m-2 w-25"
            type="button"
            (click)="resetPrices()"
          >
            Cancelar
          </button>
          <button
            class="btn btn-primary bg-ligth-mode m-2 w-25"
            type="submit"
            [disabled]="isDisabled() || pricingForm.invalid"
          >
            Guardar cambios
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

import { Injectable, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private modalRefs: BsModalRef[] = [];

  constructor(private bsModalService: BsModalService) {}

  openModal(template: TemplateRef<any>, cssClass: string): BsModalRef {
    const modalRef = this.bsModalService.show(template, {
      class: cssClass,
    });
    this.modalRefs.push(modalRef);
    return modalRef;
  }

  closeModal(modalRef: BsModalRef): void {
    modalRef.hide();
    this.modalRefs = this.modalRefs.filter((ref) => ref !== modalRef);
  }

  closeAllModals(): void {
    this.modalRefs.forEach((modalRef) => modalRef.hide());
    this.modalRefs = [];
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OrderStatus } from '@enum';
import { AuthService } from 'app/core/auth/auth.service';
import { OrderListItem } from 'app/core/interfaces/order-list-item.interface';
import { OrderService } from 'app/features/orders/services/order.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-set-order-status-modal',
  templateUrl: './set-order-status-modal.component.html',
  styleUrl: './set-order-status-modal.component.scss',
})
export class SetOrderStatusModalComponent {
  @Input() order!: OrderListItem;
  @Input() modalRef!: BsModalRef;

  @Output() updated = new EventEmitter<OrderListItem>();
  @Output() orderCanceled = new EventEmitter<OrderListItem>();

  orderStatusForm: FormGroup;
  submitted: boolean = false;

  constructor(
    private orderService: OrderService,
    private authService: AuthService,
    private fb: FormBuilder,
    private toastr: ToastrService
  ) {
    this.orderStatusForm = this.fb.group({
      estatus: ['', [Validators.required]],
    });
  }

  ngOnInit() {
    this.orderStatusForm.setValue({
      estatus: this.order.estatus,
    });
  }

  get estatusErrors() {
    const formValue = this.orderStatusForm.get('estatus');
    if (formValue?.hasError('required')) {
      return 'Campo requerido';
    }
    return '';
  }

  close = (): void => {
    if (this.modalRef) {
      this.modalRef.hide();
    }
  };

  public onSubmit = (): void => {
    this.submitted = true;
    if (this.orderStatusForm.valid) {
      this.order.estatus = this.orderStatusForm.value.estatus;
      if (this.order.estatus === OrderStatus.CANCELED) {
        this.order.user_id_cancelled = this.authService.getUserId();
      }
      this.orderService.updateOrder(this.order).subscribe(({ data, code }) => {
        if (code === 500) {
          this.submitted = false;
          return this.toastr.error(
            'No fue posible actualizar el estatus de la orden',
            '¡Error!'
          );
        }
        if (this.order.estatus === OrderStatus.CANCELED) {
          this.orderCanceled.emit(data);
          this.close();
          this.toastr.success(`¡Orden cancelada!`, '¡Éxito!');
          return;
        }
        this.updated.emit(data);
        this.toastr.success(`¡Estatus de la orden actualizado!`, '¡Éxito!');
        this.close();
        return;
      });
    }
  };
}

<app-navbar [title]="'Reportes'"></app-navbar>
<app-tab
  [tabs]="tabs"
  [activeTab]="activeTab"
  (tabChange)="onTabChange($event)"
  class="mb-3"
></app-tab>
<div [ngSwitch]="activeTab" class="mb-3">
  <app-surveys *ngSwitchCase="tabNames.SURVEYS"></app-surveys>
  <app-star-client *ngSwitchCase="tabNames.STAR_CLIENT"></app-star-client>
</div>

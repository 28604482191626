import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function noSpacesEmailValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value as string;
    const hasSpaces = /\s/.test(value);
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);

    return hasSpaces || !isValidEmail ? { noSpacesEmail: true } : null;
  };
}

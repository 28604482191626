<div class="modal-header d-flex justify-content-center border-0">
  <h4 class="modal-title text-color fw-bold mt-3">Ver y editar direcciones</h4>
</div>
<div class="modal-body text-center pt-0">
  <p class="fw-bold text-color-2">Ingresa los datos correspondientes</p>
  <nav *ngIf="addresses.length > 1">
    <ul class="pagination d-flex justify-content-center">
      <li
        class="page-item clickable"
        *ngFor="let address of addresses; let i = index"
        [ngClass]="activeAddress === i ? 'active' : ''"
      >
        <span class="page-link" (click)="changeAddress(i)">{{ i + 1 }}</span>
      </li>
    </ul>
  </nav>
  <form [formGroup]="addressForm" (ngSubmit)="onSubmit()" class="w-100 pt-2">
    <div
      class="container d-flex flex-column justify-content-center align-items-center"
    >
      <div class="d-flex w-75 mb-3">
        <div class="form-group w-75 me-1">
          <div class="form-floating">
            <input
              id="name"
              type="text"
              class="form-control"
              placeholder="Alias"
              formControlName="name"
            />
            <label for="name">Alias</label>
          </div>
          <div class="text-danger pt-1">
            {{ nameErrors }}
          </div>
        </div>
        <div class="form-group w-75 ms-1">
          <div class="form-floating">
            <input
              id="recipient_phone"
              type="text"
              class="form-control"
              placeholder="Teléfono"
              formControlName="recipient_phone"
            />
            <label for="recipient_phone">Teléfono</label>
          </div>
          <div class="text-danger pt-1">
            {{ recipientPhoneErrors }}
          </div>
        </div>
      </div>
      <div class="d-flex w-75 mb-3">
        <div class="form-group w-75 me-1">
          <div class="form-floating">
            <input
              id="postal_code"
              type="text"
              class="form-control"
              placeholder="Código Postal"
              formControlName="postal_code"
            />
            <label for="name">Código Postal</label>
          </div>
          <div class="text-danger pt-1">
            {{ postalCodeErrors }}
          </div>
        </div>
        <div class="form-group w-75 ms-1">
          <div class="form-floating">
            <input
              id="street"
              type="text"
              class="form-control"
              placeholder="Calle"
              formControlName="street"
            />
            <label for="name">Calle</label>
          </div>
          <div class="text-danger pt-1">
            {{ streetErrors }}
          </div>
        </div>
      </div>
      <div class="form-group w-75 mb-3">
        <div class="form-floating">
          <input
            id="colony"
            type="text"
            class="form-control"
            placeholder="Colonia"
            formControlName="colony"
          />
          <label for="colony">Colonia</label>
        </div>
        <div class="text-danger ps-4 pt-1">
          {{ colonyErrors }}
        </div>
      </div>
      <div class="d-flex w-75 mb-3">
        <div class="form-group w-75 me-1">
          <div class="form-floating">
            <input
              id="number"
              type="text"
              class="form-control"
              placeholder="No. Exterior"
              formControlName="number"
            />
            <label for="name">No. Exterior</label>
          </div>
          <div class="text-danger pt-1">
            {{ numberErrors }}
          </div>
        </div>
        <div class="form-group w-75 ms-1">
          <div class="form-floating">
            <input
              id="number_in"
              type="text"
              class="form-control"
              placeholder="No. Interior"
              formControlName="number_in"
            />
            <label for="name">No. Interior</label>
          </div>
          <div class="text-danger pt-1">
            {{ numberInErrors }}
          </div>
        </div>
      </div>
      <div class="d-flex w-75 mb-3">
        <div class="form-group w-75 me-1">
          <div class="form-floating">
            <input
              id="municipality"
              type="text"
              class="form-control"
              placeholder="Municipio"
              formControlName="location"
            />
            <label for="municipality">Municipio</label>
          </div>
          <div class="text-danger pt-1">
            {{ municipalityErrors }}
          </div>
        </div>
        <div class="form-group w-75 ms-1">
          <div class="form-floating">
            <select id="state" class="form-select" formControlName="state">
              <option value="Michoacán">Michoacán</option>
              <option value="Guanajuato">Guanajuato</option>
            </select>
            <label for="state">Estado</label>
          </div>
          <div class="text-danger pt-1">
            {{ stateErrors }}
          </div>
        </div>
      </div>
      <div class="form-group w-75 mb-3">
        <div class="form-floating">
          <input
            id="recipient_name"
            type="text"
            class="form-control"
            placeholder="Persona a recibir"
            formControlName="recipient_name"
          />
          <label for="recipient_name">Persona a recibir</label>
        </div>
        <div class="text-danger ps-4 pt-1">
          {{ recipientNameErrors }}
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <button
        class="btn btn-primary bg-ligth-mode ps-5 mt-5 pt-2 pb-2 pe-5"
        type="submit"
        [disabled]="isDisabled()"
      >
        Guardar cambios
      </button>
    </div>
    <div class="d-flex justify-content-center"></div>
    <label class="clickable text-ligth-mode fw-bolder mt-3" (click)="close()">
      Cancelar
    </label>
  </form>
</div>

import { Component, TemplateRef, ViewChild } from '@angular/core';
import { UserService } from '../../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { UserListItem, UsersFilter } from '@interfaces';
import { ExportModules, ExportType, UserType } from '@enum';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { EditUserModalComponent } from 'app/shared/modals/edit-user/edit-user-modal.component';
import { FileService } from 'app/core/services/file.service';
import { ModalService } from 'app/core/services/modal.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrl: './user-list.component.scss',
})
export class UserListComponent {
  @ViewChild('userData', { static: true }) userData!: TemplateRef<any>;
  @ViewChild('changeStatusButton', { static: true })
  changeStatusButton!: TemplateRef<any>;
  @ViewChild('edit') edit!: TemplateRef<any>;
  @ViewChild('create') create!: TemplateRef<any>;

  @ViewChild('userTable') table: any;

  modalRef!: BsModalRef;
  filters: UsersFilter;
  number: number | undefined;
  name: string | undefined;
  isLoading: boolean = false;
  userList: UserListItem[] = [];
  exportModule: ExportModules = ExportModules.USERS;
  type: string = UserType.USER;
  itemCount: number = 0;
  columns: any[] = [];
  exportType = ExportType;

  selectedUser!: UserListItem;

  messages = {
    emptyMessage: 'Sin resultados',
    totalMessage: 'Total',
    selectedMessage: 'Seleccionado',
  };

  constructor(
    private userService: UserService,
    private toastr: ToastrService,
    private fileService: FileService,
    private modalService: ModalService
  ) {
    this.filters = {
      page: 0,
      user_type: UserType.USER,
      name: undefined,
      number: undefined,
    };
  }

  ngOnInit() {
    this.getUserList();
    this.initTableConfig();
  }

  getUserList = async (): Promise<any> => {
    this.isLoading = true;
    this.userService
      .filtered({ ...this.filters })
      .subscribe(({ code, data, count }): any => {
        if (code === 500) {
          this.isLoading = false;
          return this.toastr.error(
            'No se pudo obtener el historial de precios',
            '¡Error!'
          );
        }
        this.userList = data;
        this.itemCount = count;
        this.isLoading = false;
      });
  };

  resetFilters = (): void => {
    this.filters = {
      page: 0,
      user_type: UserType.USER,
      name: undefined,
      number: undefined,
    };
  };

  public setPage = async (pageInfo: any): Promise<any> => {
    this.filters.page = pageInfo.offset;
    this.getUserList();
  };

  initTableConfig = (): void => {
    this.columns = [
      {
        name: '# Empleado',
        prop: 'number',
        flexGrow: 1,
        resizeable: false,
        draggable: false,
      },
      {
        name: 'Nombre',
        prop: 'complete_name',
        flexGrow: 1,
        resizeable: false,
        draggable: false,
      },
      {
        name: 'Correo',
        prop: 'email',
        flexGrow: 1,
        resizeable: false,
        draggable: false,
      },
      {
        name: 'Editar',
        flexGrow: 1,
        resizeable: false,
        draggable: false,
        sortable: false,
        cellTemplate: this.userData,
      },
      {
        name: 'Acciones',
        flexGrow: 1,
        resizeable: false,
        draggable: false,
        sortable: false,
        cellTemplate: this.changeStatusButton,
      },
    ];
  };

  openEditModal(user: any) {
    this.selectedUser = user;
    this.modalRef = this.modalService.openModal(
      this.edit,
      'modal-lg modal-rounded'
    );
  }

  openCreateModal() {
    this.modalRef = this.modalService.openModal(
      this.create,
      'modal-lg modal-rounded'
    );
  }

  userUpdated(updatedUser: UserListItem) {
    const index = this.userList.findIndex((user) => user.id === updatedUser.id);
    if (index !== -1) {
      this.userList[index] = updatedUser;
      this.userList = [...this.userList];
    }
  }

  userCreated() {
    this.getUserList();
  }

  changeStatus = async (user: UserListItem, enabled: number): Promise<any> => {
    this.isLoading = true;
    this.userService
      .changeStatus(enabled, user.id)
      .subscribe(({ code, data }): any => {
        if (code === 500) {
          this.isLoading = false;
          return this.toastr.error(
            'No se pudo actualizar el estatus del usuario',
            '¡Error!'
          );
        }
        this.toastr.success(
          `¡Usuario ${enabled ? 'habilidato' : 'deshabilitado'}!`,
          '¡Éxito!'
        );
        this.isLoading = false;
        this.getUserList();
      });
  };

  export = async (format: ExportType): Promise<any> => {
    this.userService
      .export(format, this.type, this.filters)
      .subscribe(({ code, data }: any) => {
        if (code === 500) {
          return this.toastr.error('No se pudo generar el archivo', '¡Error!');
        }
        this.toastr.success('¡Archivo descargado!', '¡Éxito!');
        this.fileService.downloadFile(data.url, data.name);
        return;
      });
  };

  onSort(event: any) {
    this.table.offset = this.filters.page;
  }

  filter() {
    this.filters.page = 0;
    this.getUserList();
  }
}

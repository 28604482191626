import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TabName } from '@enum';
import { TabData } from '@interfaces';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrl: './pricing.component.scss',
})
export class PricingComponent {
  tabNames = TabName;

  tabs: TabData[] = [
    {
      name: this.tabNames.CYLINDER_PRICING,
      label: 'Precio estimado del cilindro',
    },
    { name: this.tabNames.PRICE_HISTORY, label: 'Historial de precios' },
  ];

  activeTab: string = this.tabNames.CYLINDER_PRICING;
  constructor() {}

  ngOnInit() {}

  onTabChange(selectedTab: string): void {
    this.activeTab = selectedTab;
  }
}

<div class="container-fluid ps-0 pe-3 mt-0 pt-3 w-100 app-bg">
  <ul class="nav nav-tabs custom-nav shadow card-bg">
    <li *ngFor="let tab of tabs" class="nav-item clickable">
      <a
        class="nav-link fw-bolder"
        [ngClass]="{ active: activeTab === tab.name }"
        (click)="setActiveTab(tab.name)"
        >{{ tab.label }}</a
      >
    </li>
  </ul>
</div>

<app-navbar [title]="'Gestión de precio promedio'"></app-navbar>
<app-tab
  [tabs]="tabs"
  [activeTab]="activeTab"
  (tabChange)="onTabChange($event)"
  class="mb-3"
></app-tab>
<div [ngSwitch]="activeTab" class="mb-3">
  <app-cylinder-pricing
    *ngSwitchCase="tabNames.CYLINDER_PRICING"
  ></app-cylinder-pricing>
  <app-price-history *ngSwitchCase="tabNames.PRICE_HISTORY"></app-price-history>
</div>

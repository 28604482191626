<app-navbar [title]="'Perfil'"></app-navbar>

<div class="container-fluid ps-0 pe-3 mt-3 mb-3 w-100">
  <div class="card shadow border-0 card-bg">
    <div class="card-body">
      <h5 class="card-title text-color ps-4 fw-bold">Información personal</h5>
      <p class="card-text"></p>
      <hr />
      <div class="d-flex flex-column justify-content-center align-items-center">
        <div class="img-container shadow mb-5">
          <img
            [src]="imageSrc || 'assets/user-profile-picture.svg'"
            class="profile-image-size"
          />
          <input
            type="file"
            (change)="onFileChange($event)"
            id="file-input"
            hidden
          />
          <button
            class="btn-edit bg-ligth-mode shadow border-0"
            (click)="triggerFileInput()"
            type=""
          >
            <img src="assets/edit.svg" alt="edit" />
          </button>
        </div>
        <form [formGroup]="profileForm" (ngSubmit)="onSubmit()" class="w-100">
          <div class="container">
            <div class="row mb-4">
              <div class="col-6">
                <div class="form-group" style="margin-left: 50px; width: 85%">
                  <div class="form-floating">
                    <input
                      id="number"
                      type="text"
                      class="form-control"
                      placeholder="Número de empleado"
                      formControlName="number"
                    />
                    <label for="number">Número de empleado</label>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <div
                    class="form-floating"
                    style="margin-left: 30px; width: 85%"
                  >
                    <input
                      id="email"
                      type="email"
                      class="form-control"
                      placeholder="Correo electrónico"
                      formControlName="email"
                    />
                    <label for="email">Correo electrónico</label>
                  </div>
                  <div
                    *ngIf="
                      profileForm.get('email')?.dirty ||
                      profileForm.get('email')?.touched
                    "
                  >
                    <div class="text-danger ps-4 pt-1">
                      {{ emailErrors }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="form-group d-flex flex-column align-items-center">
                  <div class="form-floating" style="width: 60%">
                    <input
                      id="name"
                      type="text"
                      class="form-control"
                      placeholder="Nombre"
                      formControlName="complete_name"
                      (input)="onInput($event)"
                      (paste)="onPaste($event)"
                    />
                    <label for="name">Nombre completo</label>
                  </div>
                  <div
                    *ngIf="
                      profileForm.get('complete_name')?.dirty ||
                      profileForm.get('complete_name')?.touched
                    "
                  >
                    <div class="text-danger ps-5 pt-1">
                      {{ completeNameErrors }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <hr class="custom-separator mt-5" />
          </div>
          <div class="container pb-5">
            <h6 class="text-color pt-3 ps-3 pb-4 fw-bold">
              Datos de contraseña
            </h6>
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <div
                    class="input-group"
                    style="margin-left: 50px; width: 85%"
                  >
                    <div class="form-floating">
                      <input
                        id="password"
                        [type]="isVisible ? 'text' : 'password'"
                        class="form-control border-end-0"
                        placeholder="Nueva contraseña"
                        formControlName="password"
                        (input)="removeInitialSpaces()"
                      />
                      <label for="password text-color-2"
                        >Nueva contraseña</label
                      >
                    </div>
                    <span
                      (click)="changePasswordVisibility(true)"
                      class="input-group-text bg-transparent clickable"
                    >
                      <img
                        [src]="
                          isVisible
                            ? 'assets/lock-slash.svg'
                            : '/assets/unlock.svg'
                        "
                        alt="user"
                      />
                    </span>
                  </div>
                  <div
                    *ngIf="
                      profileForm.get('password')?.dirty ||
                      profileForm.get('password')?.touched
                    "
                  >
                    <div class="text-danger pt-1 ps-5">
                      {{ passwordErrors }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <div
                    class="input-group"
                    style="margin-left: 30px; width: 85%"
                  >
                    <div class="form-floating">
                      <input
                        id="confirmPassword"
                        [type]="isVisibleConfirm ? 'text' : 'password'"
                        class="form-control border-end-0"
                        placeholder="Confirmar contraseña"
                        formControlName="confirmPassword"
                      />
                      <label for="confirmPassword text-color-2"
                        >Confirmar contraseña</label
                      >
                    </div>
                    <span
                      (click)="changePasswordVisibility()"
                      class="input-group-text bg-transparent clickable"
                    >
                      <img
                        [src]="
                          isVisibleConfirm
                            ? 'assets/lock-slash.svg'
                            : '/assets/unlock.svg'
                        "
                        alt="user"
                      />
                    </span>
                  </div>
                  <div
                    *ngIf="
                      profileForm.get('confirmPassword')?.dirty ||
                      profileForm.get('confirmPassword')?.touched
                    "
                  >
                    <div class="text-danger ps-4">
                      {{ confirmPasswordErrors }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <button
              class="btn btn-solid-bg pt-2 pb-2 ps-5 pe-5"
              type="submit"
              [disabled]="isDisabled()"
            >
              Guardar cambios
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

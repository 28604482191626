import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './features/login/login.component';
import { DashboardComponent } from './features/dashboard/dashboard.component';
import { OrdersComponent } from './features/orders/orders.component';
import { UsersComponent } from './features/users/users.component';
import { ReportsComponent } from './features/reports/reports.component';
import { PricingComponent } from './features/pricing/pricing.component';
import { ProfileComponent } from './features/profile/profile.component';
import { RecoverPasswordComponent } from './features/recover-password/recover-password.component';
import { ChangePasswordComponent } from './features/change-password/change-password.component';
import { MainLayoutComponent } from './shared/main-layout/main-layout.component';
import { AuthLayoutComponent } from './shared/auth-layout/auth-layout.component';
import { authLoginGuard, authGuard, changePasswordGuard } from '@guards';
import { roleGuard } from './core/guards/role.guard';

const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        redirectTo: '/dashboard',
        pathMatch: 'full',
      },
      {
        path: 'orders',
        component: OrdersComponent,
        canActivate: [authGuard],
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [authGuard, roleGuard],
        data: { expectedRole: ['admin_role', 'call_center_role'] },
      },
      {
        path: 'users',
        component: UsersComponent,
        canActivate: [authGuard, roleGuard],
        data: { expectedRole: ['admin_role'] },
      },
      {
        path: 'reports',
        component: ReportsComponent,
        canActivate: [authGuard, roleGuard],
        data: { expectedRole: ['admin_role', 'call_center_role'] },
      },
      {
        path: 'pricing',
        component: PricingComponent,
        canActivate: [authGuard, roleGuard],
        data: { expectedRole: ['admin_role', 'call_center_role'] },
      },
      {
        path: 'profile',
        component: ProfileComponent,
        canActivate: [authGuard],
      },
    ],
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent,
        canActivate: [authLoginGuard],
      },
      {
        path: 'recover-password',
        component: RecoverPasswordComponent,
      },
      {
        path: 'change-password/:token',
        component: ChangePasswordComponent,
        canActivate: [changePasswordGuard],
      },
    ],
  },
  {
    path: '**',
    redirectTo: '/login',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

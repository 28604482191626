import { Component } from '@angular/core';
import { TabName } from '@enum';
import { TabData } from '@interfaces';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrl: './users.component.scss',
})
export class UsersComponent {
  tabNames = TabName;

  tabs: TabData[] = [
    {
      name: this.tabNames.COLABORATORS,
      label: 'Colaboradores',
    },
    { name: this.tabNames.CLIENTS, label: 'Clientes' },
  ];

  activeTab: string = this.tabNames.COLABORATORS;
  constructor() {}

  ngOnInit() {}

  onTabChange(selectedTab: string): void {
    this.activeTab = selectedTab;
  }
}

import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { PricingService } from '../../services/pricing.service';
import { Price } from '@interfaces';
import { CylinderType } from '@enum';
import { AuthService } from 'app/core/auth/auth.service';
import { conditionalRequiredValidator } from '@validators';

@Component({
  selector: 'app-cylinder-pricing',
  templateUrl: './cylinder-pricing.component.html',
  styleUrl: './cylinder-pricing.component.scss',
})
export class CylinderPricingComponent {
  submitted: boolean = true;
  pricingForm: FormGroup;
  cylinder30: Price;
  cylinder20: Price;
  originalCylinder30: number = 0;
  originalCylinder20: number = 0;
  validRegex: RegExp = /^\d*\.?\d{0,2}$/;

  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private pricingService: PricingService,
    private authService: AuthService
  ) {
    this.pricingForm = this.fb.group({
      amount30: ['', [Validators.required]],
      amount20: ['', [Validators.required]],
    });
    this.cylinder30 = {
      amount: 0,
      product: CylinderType.cylinder30,
      user_id: this.authService.getUserId(),
    };
    this.cylinder20 = {
      amount: 0,
      product: CylinderType.cylinder20,
      user_id: this.authService.getUserId(),
    };
  }

  ngOnInit() {
    this.getPrices();
  }

  public onSubmit = async (): Promise<void> => {
    this.submitted = true;
    if (this.pricingForm.valid) {
      const prices: Price[] = [];
      const { amount20, amount30 } = this.pricingForm.value;
      this.updatePricesIfChanged(
        this.cylinder30,
        this.originalCylinder30,
        amount30,
        prices
      );
      this.updatePricesIfChanged(
        this.cylinder20,
        this.originalCylinder20,
        amount20,
        prices
      );
      this.pricingService
        .create(Object.values(prices))
        .subscribe(({ data, code }) => {
          if (code === 500) {
            this.submitted = false;
            this.cylinder30.amount = 0;
            this.cylinder20.amount = 0;
            return this.toastr.error(
              'No se pudieron actualizar los precios',
              '¡Error!'
            );
          }
          this.toastr.success(
            'Precio(s) actualizados correctamente',
            '¡Éxito!'
          );
          this.originalCylinder20 = this.cylinder20.amount
            ? this.cylinder20.amount
            : this.originalCylinder20;
          this.originalCylinder30 = this.cylinder30.amount
            ? this.cylinder30.amount
            : this.originalCylinder30;
          return;
        });
    }
  };

  public resetPrices = (): void => {
    this.pricingForm.setValue({
      amount30: this.originalCylinder30,
      amount20: this.originalCylinder20,
    });
  };

  public getPrices = async (): Promise<void> => {
    this.pricingService.latest().subscribe(({ data, code }) => {
      if (code === 500) {
        this.submitted = false;
        return this.toastr.error(
          'No se pudieron obtener los ultimos precios',
          '¡Error!'
        );
      }
      const {
        twenty: { amount: amount20 },
        thirty: { amount: amount30 },
      } = data;
      this.originalCylinder20 = amount20;
      this.originalCylinder30 = amount30;
      this.setPrices(amount30, amount20);
      return;
    });
  };

  validateInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    let value = input.value;

    if (value && !this.validRegex.test(value)) {
      input.value = value.slice(0, -1);
    }

    const parts = value.split('.');
    if (parts.length > 2) {
      input.value = parts[0] + '.' + parts.slice(1).join('');
    }
  }

  handlePaste(event: ClipboardEvent): void {
    const pastedData = event.clipboardData?.getData('text');
    if (
      pastedData &&
      (pastedData.includes('-') ||
        isNaN(Number(pastedData)) ||
        !this.validRegex.test(pastedData))
    ) {
      event.preventDefault();
    }
  }

  setPrices = (amount30: number, amount20: number): void => {
    this.pricingForm.setValue({
      amount30,
      amount20,
    });
  };

  isDisabled = (): boolean => {
    const { amount20, amount30 } = this.pricingForm.value;
    if (
      this.originalCylinder30 === amount30 &&
      this.originalCylinder20 === amount20
    ) {
      return true;
    }
    return false;
  };

  getFieldErrors(controlName: string): string {
    const control = this.pricingForm.get(controlName);
    return control?.hasError('required') ? 'Precio requerido.' : '';
  }

  get amount30Errors() {
    return this.getFieldErrors('amount30');
  }

  get amount20Errors() {
    return this.getFieldErrors('amount20');
  }

  private updatePricesIfChanged(
    cylinder: Price,
    oldAmount: number,
    newAmount: number,
    prices: Price[]
  ): void {
    if (oldAmount !== newAmount) {
      cylinder.amount = newAmount;
      prices.push(cylinder);
    }
  }
}

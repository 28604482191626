import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
  styleUrl: './tab.component.scss'
})
export class TabComponent {
  @Input() tabs: { name: string, label: string }[] = [];
  @Input() activeTab: string = '';
  @Output() tabChange = new EventEmitter<string>();

  setActiveTab(tabName: string): void {
    this.activeTab = tabName;
    this.tabChange.emit(this.activeTab);
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { environment } from '@env/environment';
import { OrderListItem } from 'app/core/interfaces/order-list-item.interface';
import { OrderService } from 'app/features/orders/services/order.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-upload-bill-modal',
  templateUrl: './upload-bill-modal.component.html',
  styleUrl: './upload-bill-modal.component.scss',
})
export class UploadBillModalComponent {
  @Input() order!: OrderListItem;
  @Input() modalRef!: BsModalRef;

  @Output() updated = new EventEmitter<OrderListItem>();

  submitted: boolean = false;

  url: string = environment.baseUrl;

  pdf!: File;
  xml!: File;

  constructor(
    private orderService: OrderService,
    private toastr: ToastrService
  ) {}

  uploadBill = (): void => {
    this.submitted = true;
    if (!this.pdf || !this.xml) {
      this.toastr.error('Debes subir ambos archivos, PDF y XML', '¡Error!');
      return;
    }
    this.orderService
      .uploadBill(this.pdf, this.xml, this.order.id + '', this.url)
      .subscribe(({ code, data }) => {
        if (code === 500) {
          this.toastr.error('No fue posible enviar la factura', '¡Error!');
          this.submitted = false;
          return;
        }
        this.updated.emit();
        this.close();
        this.toastr.success('Factura enviada', '¡Éxito!');
        this.submitted = false;
        return;
      });
  };

  onPDFChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    const file = input.files?.[0];

    if (file) {
      if (this.isValid(file, 'PDF')) {
        this.pdf = file;
      } else {
        this.toastr.error('El archivo debe ser PDF', '¡Error!');
        this.pdf = null as any;
      }
    }
  }

  onXMLChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    const file = input.files?.[0];

    if (file) {
      if (this.isValid(file, 'XML')) {
        this.xml = file;
      } else {
        this.toastr.error('El archivo debe ser XML', '¡Error!');
        this.pdf = null as any;
      }
    }
  }

  isValid(file: File, type: string): boolean {
    const fileType = file.type;

    if (type === 'PDF' && fileType === 'application/pdf') {
      return true;
    }

    if (type === 'XML' && fileType === 'text/xml') {
      return true;
    }

    return false;
  }

  close = (): void => {
    if (this.modalRef) {
      this.modalRef.hide();
    }
  };
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserType } from '@enum';
import { UserListItem } from '@interfaces';
import { lettersOnlyValidator, noSpacesEmailValidator } from '@validators';
import { UserCreateDto } from 'app/core/dtos/user-create.dto';
import { UserService } from 'app/features/users/services/user.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-new-user-modal',
  templateUrl: './new-user-modal.component.html',
  styleUrl: './new-user-modal.component.scss',
})
export class NewUserModalComponent {
  @Input() modalRef!: BsModalRef;

  @Output() userCreated = new EventEmitter();

  submitted: boolean = false;
  newUserForm: FormGroup;
  isVisible: boolean = false;
  userDto: UserCreateDto;

  constructor(
    private userService: UserService,
    private fb: FormBuilder,
    private toastr: ToastrService
  ) {
    this.userDto = {
      complete_name: '',
      email: '',
      last_name: '',
      name: '',
      number: 0,
      phone: '',
      type_user: UserType.USER,
    };
    this.newUserForm = this.fb.group({
      number: ['', [Validators.required, Validators.maxLength(5)]],
      email: ['', [Validators.required, noSpacesEmailValidator()]],
      complete_name: [
        '',
        [
          Validators.required,
          Validators.maxLength(50),
          Validators.minLength(1),
          lettersOnlyValidator(),
        ],
      ],
      admin_role: ['', []],
      finance_role: ['', []],
    });
  }

  ngOnInit() {
    this.newUserForm.setValue({
      email: '',
      complete_name: '',
      number: '',
      admin_role: 'null',
      finance_role: 'null',
      call_center_role: 'null',
    });
    this.onChanges();
  }

  close = (): void => {
    if (this.modalRef) {
      this.modalRef.hide();
    }
  };

  public onSubmit = (): void => {
    this.submitted = true;
    if (this.newUserForm.valid) {
      this.setUserDto(this.newUserForm.value);
      this.userService
        .createUser(this.userDto)
        .subscribe(({ data, code, errors }) => {
          if (code === 500) {
            this.submitted = false;
            if (errors) {
              return this.toastr.error(errors.email[0], '¡Error!');
            }
            return this.toastr.error(
              'No fue posible crear un nuevo usuario',
              '¡Error!'
            );
          }
          this.userCreated.emit();
          this.toastr.success(`¡Usuario creado!`, '¡Éxito!');
          this.close();
          return;
        });
    }
  };

  get numberErrors() {
    const numberControl = this.newUserForm.get('number');
    if (numberControl?.hasError('required')) {
      return 'Campo requerido';
    } else if (numberControl?.hasError('maxlength')) {
      return 'Debe contener máximo 5 caracteres.';
    }
    return '';
  }

  get completeNameErrors() {
    const nameControl = this.newUserForm.get('complete_name');
    if (nameControl?.hasError('required')) {
      return 'Campo requerido';
    } else if (nameControl?.hasError('lettersOnly')) {
      return 'Solo se permiten letras';
    } else if (nameControl?.hasError('maxlength')) {
      return 'Debe tener máximo 50 caracteres';
    } else if (nameControl?.hasError('minlength')) {
      return 'Debe tener mínimo 1 caracter';
    }
    return '';
  }

  get emailErrors() {
    const emailControl = this.newUserForm.get('email');
    if (emailControl?.hasError('required')) {
      return 'Campo requerido';
    } else if (emailControl?.hasError('noSpacesEmail')) {
      return 'Formato inválido';
    }
    return '';
  }

  onInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    const value = input.value;
    const trimmedValue = value.trimStart();
    this.newUserForm
      .get('complete_name')
      ?.setValue(trimmedValue, { emitEvent: false });
  }

  onPaste(event: ClipboardEvent): void {
    const pastedData = event.clipboardData?.getData('text') || '';
    const trimmedData = pastedData.trimStart();
    event.preventDefault();
    const input = event.target as HTMLInputElement;
    const cursorPosition = input.selectionStart ?? 0;
    const currentValue = input.value;
    const newValue =
      currentValue.slice(0, cursorPosition) +
      trimmedData +
      currentValue.slice(input.selectionEnd ?? cursorPosition);
    input.value = newValue.trimStart();
    this.newUserForm.patchValue({ complete_name: input.value });
  }

  setUserDto = (userForm: any): void => {
    this.userDto = {
      complete_name: userForm.complete_name,
      email: userForm.email,
      last_name: 'default',
      name: 'default',
      number: userForm.number,
      phone: '9999999999',
      admin_role: userForm.admin_role === '1' ? 1 : null,
      finance_role: userForm.finance_role === '1' ? 1 : null,
      call_center_role:
        userForm.admin_role === 'null' && userForm.finance_role === 'null'
          ? 1
          : null,
      type_user: UserType.USER,
    };
  };

  onChanges(): void {
    this.newUserForm.get('admin_role')?.valueChanges.subscribe((value) => {
      if (value === '1') {
        this.newUserForm.get('finance_role')?.setValue('null');
      }
    });

    this.newUserForm.get('finance_role')?.valueChanges.subscribe((value) => {
      if (value === '1') {
        this.newUserForm.get('admin_role')?.setValue('null');
      }
    });
  }
}

import { Component } from '@angular/core';
import { OrdersFilters, User } from '@interfaces';
import { AuthService } from 'app/core/auth/auth.service';
import { OrderService } from '../orders/services/order.service';
import { ToastrService } from 'ngx-toastr';
import { OrderListItem } from 'app/core/interfaces/order-list-item.interface';
import { ThemeService } from 'app/core/services/theme.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss',
})
export class DashboardComponent {
  user!: User;
  filters: OrdersFilters;
  orderList!: OrderListItem[];
  isDarkMode: boolean = false;

  orderStatus = [
    {
      src: 'assets/truck-info.svg',
      class: 'status-new-bg',
      label: 'Pedidos nuevos',
      estatus: 'Nuevo',
      total: 0,
    },
    {
      src: 'assets/truck-warning.svg',
      class: 'status-inprocess-bg',
      label: 'Pedidos en proceso',
      estatus: 'En proceso',
      total: 0,
    },
    {
      src: 'assets/truck-success.svg',
      class: 'status-finished-bg',
      label: 'Pedidos completados',
      estatus: 'Terminado',
      total: 0,
    },
    {
      src: 'assets/truck-danger.svg',
      class: 'status-canceled-bg',
      label: 'Pedidos cancelados',
      estatus: 'Cancelado',
      total: 0,
    },
  ];

  constructor(
    private authService: AuthService,
    private ordersService: OrderService,
    private toastr: ToastrService,
    private themeService: ThemeService,
    public datePipe: DatePipe
  ) {
    this.filters = {
      size: 10,
      page: 0,
      statusDone: false,
      statusNew: false,
      statusCanceled: false,
      statusInProgress: false,
    };
  }

  ngOnInit() {
    this.user = this.authService.getUserData();
    this.getOrders();
    this.themeService.darkTheme$.subscribe((isDark) => {
      this.isDarkMode = isDark;
    });
  }

  getSaludo(): string {
    const horaActual = new Date().getHours();

    if (horaActual >= 6 && horaActual < 12) {
      return 'Buenos días';
    } else if (horaActual >= 12 && horaActual < 18) {
      return 'Buenas tardes';
    } else {
      return 'Buenas noches';
    }
  }

  getName(): string {
    return this.user.complete_name || '';
  }

  getOrders = async (): Promise<any> => {
    const { statusCanceled, statusDone, statusInProgress, statusNew } =
      this.filters;
    this.filters.size = 10;
    if (statusCanceled || statusDone || statusInProgress || statusNew) {
      this.filters.size = 9999;
    }
    this.ordersService
      .filtered({ ...this.filters })
      .subscribe(({ data, counts, code }): any => {
        if (code === 500) {
          return this.toastr.error(
            'No se han podido obtener los estatus',
            '¡Error!'
          );
        }
        this.orderStatus.forEach((status) => {
          const result = counts.find((c: any) => c.estatus === status.estatus);
          if (result) {
            status.total = result.total;
          }
        });
        this.orderList = data;
        return;
      });
  };

  setActive(estatus: string) {
    switch (estatus) {
      case 'Terminado':
        this.filters.statusDone = !this.filters.statusDone;
        break;
      case 'Nuevo':
        this.filters.statusNew = !this.filters.statusNew;
        break;
      case 'Cancelado':
        this.filters.statusCanceled = !this.filters.statusCanceled;
        break;
      case 'En proceso':
        this.filters.statusInProgress = !this.filters.statusInProgress;
        break;
    }
    this.getOrders();
  }

  setClass(estatus: string) {
    let cssClass: string = '';
    switch (estatus) {
      case 'Terminado':
        cssClass = this.filters.statusDone ? 'badge-active' : 'badge-inactive';
        break;
      case 'Nuevo':
        cssClass = this.filters.statusNew ? 'badge-active' : 'badge-inactive';
        break;
      case 'Cancelado':
        cssClass = this.filters.statusCanceled
          ? 'badge-active'
          : 'badge-inactive';
        break;
      case 'En proceso':
        cssClass = this.filters.statusInProgress
          ? 'badge-active'
          : 'badge-inactive';
        break;
    }
    return { cssClass };
  }

  setTruck(estatus: string) {
    let src: string = '';
    let cssClass: string = '';
    switch (estatus) {
      case 'Terminado':
        cssClass = 'status-finished-bg';
        src = 'assets/truck-green.svg';
        break;
      case 'Nuevo':
        cssClass = 'status-new-bg';
        src = 'assets/truck-blue.svg';
        break;
      case 'Cancelado':
        cssClass = 'status-canceled-bg';
        src = 'assets/truck-red.svg';
        break;
      case 'En proceso':
        cssClass = 'status-inprocess-bg';
        src = 'assets/truck-yellow.svg';
        break;
    }
    return { src, cssClass };
  }

  resetFilters() {
    this.filters.statusDone = false;
    this.filters.statusNew = false;
    this.filters.statusCanceled = false;
    this.filters.statusInProgress = false;
    this.getOrders();
  }
}

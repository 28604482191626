<div
  class="modal-header d-flex justify-content-between align-items-center border-0 h-100 w-100"
>
  <h5 class="modal-title text-color fw-bold mt-3 mx-auto">
    Detalles del pedido
  </h5>
  <button type="button" class="btn-close ms-0" (click)="modalRef.hide()">
    <span aria-hidden="true" class="visually-hidden text-color">&times;</span>
  </button>
</div>
<hr class="custom-separator ms-3 mt-2 mb-3" />
<div class="modal-body pt-3 mt-1">
  <div class="row mb-4">
    <div class="col-5">
      <div class="card shadow border-0 dashboard-card-bg">
        <div
          class="card-body d-flex flex-column justify-content-center align-items-center text-color"
        >
          <img
            [src]="
              order.employee && order.employee.path_profile
                ? order.employee.path_profile
                : 'assets/user-profile-picture.svg'
            "
            class="profile-image-size"
          />
          <label>{{
            order.employee ? order.employee.complete_name : "-"
          }}</label>
          <h6 class="mt-2">
            {{ order.employee ? "Empleado asignado" : "Sin empleado asignado" }}
          </h6>
        </div>
      </div>
    </div>
    <div
      class="col-7 d-flex align-items-center justify-content-center"
      *appHasRole="['admin_role', 'finance_role']"
    >
      <div class="d-flex justify-content-between w-100">
        <div
          class="d-flex flex-column justify-content-center align-items-start w-50 text-color-2"
        >
          <label class="equal-height">Nombre</label>
          <label class="equal-height mt-2">Código Postal</label>
          <label class="equal-height mt-2">RFC</label>
          <label class="equal-height mt-2">Régimen fiscal</label>
          <label class="equal-height mt-2">Correo electrónico</label>
        </div>
        <div
          class="d-flex flex-column justify-content-center align-items-end w-50 h-100 text-color-2"
        >
          <label class="equal-height">{{
            order.fiscal_data && order.fiscal_data.name
              ? order.fiscal_data.name
              : "-"
          }}</label>
          <label class="equal-height mt-2">{{
            order.fiscal_data && order.fiscal_data.postal_code
              ? order.fiscal_data.postal_code
              : "-"
          }}</label>
          <label class="equal-height mt-2">{{
            order.fiscal_data && order.fiscal_data.RFC
              ? order.fiscal_data.RFC
              : "-"
          }}</label>
          <label class="equal-height mt-2">{{
            order.fiscal_data && order.fiscal_data.tax_system
              ? order.fiscal_data.tax_system
              : "-"
          }}</label>
          <label class="equal-height mt-2">{{
            order.fiscal_data && order.fiscal_data.email
              ? order.fiscal_data.email
              : "-"
          }}</label>
        </div>
      </div>
    </div>
  </div>
  <div
    *appHasRole="['admin_role', 'finance_role']"
    class="d-flex justify-content-center"
  >
    <button
      class="btn btn-primary bg-ligth-mode w-50 mb-3"
      (click)="dowloadFile()"
    >
      Descargar constancia de situación fiscal
    </button>
  </div>
  <div class="row mb-4">
    <div class="col-8">
      <div class="card shadow border-0 h-100 dashboard-card-bg">
        <div
          class="card-body d-flex flex-column justify-content-center align-items-start"
        >
          <label>
            Cliente:
            <span class="text-color-primary fw-bolder">{{
              order.customer && order.customer.complete_name
                ? order.customer.complete_name
                : "-"
            }}</span></label
          >
          <label>
            Recibe:
            <span class="text-color-primary fw-bolder">{{
              order.address && order.address.recipient_name
                ? order.address.recipient_name
                : "-"
            }}</span></label
          >
          <label>
            Dirección:
            <span class="text-color-primary fw-bold"
              >{{
                order.address && order.address.name ? order.address.name : "-"
              }}:</span
            >
            <span>{{ getAddress() }}</span></label
          >
          <label>
            Télefono:
            <span class="text-color-primary fw-bolder">{{
              order.address && order.address.recipient_phone
                ? order.address.recipient_phone
                : "-"
            }}</span></label
          >
        </div>
      </div>
    </div>
    <div class="col-4">
      <div class="card shadow border-0 w-100 h-100 dashboard-card-bg">
        <div class="card-body">
          <div
            class="d-flex flex-column align-items-center justify-content-center h-100"
          >
            <label>Número de folio:</label>
            <h5 class="truncate-text">
              {{ order.number ? order.number : "Sin asignar" }}
            </h5>
            <label class="text-color-primary order-date-text mt-2">{{
              order.created_at
            }}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card shadow border-0 mt-3 w-100 dashboard-card-bg mb-3">
    <div class="card-title"></div>
    <div class="card-body d-flex flex-column align-items-center">
      <div class="d-flex justify-content-between w-100">
        <div class="d-flex flex-column text-center align-items-center">
          <label>Tipo de producto:</label>
          <img
            [src]="
              order.type_product === productType.TANK
                ? 'assets/tank.svg'
                : 'assets/cilinder.svg'
            "
            class="product-type-icon-size my-2"
          />
          <h6 class="fw-bolder text-color-primary order-date-text">
            {{ order.type_product }}
          </h6>
        </div>
        <div class="d-flex flex-column text-center align-items-center">
          <label>Capacidad</label>
          <h6 class="fw-bolder mt-4">{{ order.capacity }}</h6>
        </div>
        <div class="d-flex flex-column text-center align-items-center">
          <label>Cantidad</label>
          <h6 class="fw-bolder mt-4">{{ order.quantity }}</h6>
        </div>
        <div class="d-flex flex-column text-center align-items-center">
          <label>Metodo de pago</label>
          <h6 class="fw-bolder mt-4">{{ order.payment }}</h6>
        </div>
        <div class="d-flex flex-column text-center align-items-center">
          <label>Estatus</label>
          <span
            class="badge rounded-pill pill-font-size d-flex m-1 mt-4"
            [ngClass]="setStatusColor(order.estatus)"
            >{{ order.estatus }}</span
          >
        </div>
      </div>
      <hr class="custom-separator mt-3" />
      <div class="px-3 d-flex flex-column justify-content-start w-100 mb-3">
        <label class="align-text-top">Comentarios de cliente: </label>
        <div class="card border-0">
          <div class="card-body comment-background more-rounded">
            {{ order.commentary }}
          </div>
        </div>
        <label
          class="align-text-top mt-3"
          *appHasRole="['admin_role', 'call_center_role']"
          >Comentarios de call center:
        </label>
        <div
          class="card border-0"
          *appHasRole="['admin_role', 'call_center_role']"
        >
          <div class="card-body comment-background more-rounded">
            {{ order.response }}
          </div>
        </div>
      </div>
      <div
        *appHasRole="['finance_role']"
        class="d-flex justify-content-center mb-3"
      >
        <button class="btn btn-danger px-5" (click)="sendBillNotification()">
          Envíar
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal-header d-flex justify-content-center border-0">
  <h4 class="modal-title text-color fw-bold mt-3">Datos de facturación</h4>
</div>
<div class="modal-body text-center pt-0">
  <p class="fw-bold text-color-2">Ingresa los datos correspondientes</p>
  <form [formGroup]="fiscalDataForm" (ngSubmit)="onSubmit()" class="w-100 pt-2">
    <div
      class="container d-flex flex-column justify-content-center align-items-center"
    >
      <div class="form-group w-75 mb-3">
        <div class="form-floating">
          <input
            id="name"
            type="text"
            class="form-control"
            placeholder="Nombre completo"
            formControlName="name"
          />
          <label for="name">Nombre completo</label>
        </div>
        <div class="text-danger ps-4 pt-1">
          {{ nameErrors }}
        </div>
      </div>
      <div class="d-flex w-75 mb-3">
        <div class="form-group w-75 mb-3">
          <div class="form-floating">
            <input
              id="email"
              type="text"
              class="form-control"
              placeholder="Correo electrónico"
              formControlName="email"
            />
            <label for="email">Correo electrónico</label>
          </div>
          <div class="text-danger ps-4 pt-1">
            {{ emailErrors }}
          </div>
        </div>
        <div class="form-group w-75 me-1">
          <div class="form-floating">
            <input
              id="postal_code"
              type="text"
              class="form-control"
              placeholder="Código Postal"
              formControlName="postal_code"
            />
            <label for="postal_code">Código Postal</label>
          </div>
          <div class="text-danger pt-1">
            {{ postalCodeErrors }}
          </div>
        </div>
      </div>
      <div class="d-flex w-75 mb-3">
        <div class="form-group w-75 ms-1">
          <div class="form-floating">
            <select
              id="type"
              class="form-select"
              formControlName="type"
              (change)="changeTaxSystem($event)"
            >
              <option value="Moral">Moral</option>
              <option value="Física">Física</option>
            </select>
            <label for="type">Tipo de persona</label>
          </div>
          <div class="text-danger pt-1">
            {{ typeErrors }}
          </div>
        </div>
        <div class="form-group w-75 ms-1">
          <div class="form-floating">
            <select
              id="tax_system"
              class="form-select"
              formControlName="tax_system"
            >
              <option *ngFor="let tax of selectedTaxSystem" [value]="tax">
                {{ tax }}
              </option>
            </select>
            <label for="tax_system">Régimen Fiscal</label>
          </div>
          <div class="text-danger pt-1">
            {{ taxSystemErrors }}
          </div>
        </div>
      </div>
      <div class="form-group w-75 mb-4">
        <div class="form-floating">
          <input
            id="RFC"
            type="text"
            class="form-control"
            placeholder="RFC"
            formControlName="RFC"
          />
          <label for="RFC">RFC</label>
        </div>
        <div class="text-danger ps-4 pt-1">
          {{ rfcErrors }}
        </div>
      </div>
      <div
        *ngIf="selectedBilling.record"
        class="card shadow w-75 border-0 dashboard-card-bg"
      >
        <div class="card-body">
          <h6 class="card-title text-color-2">Constancia fiscal</h6>
          <div class="card card-custom-border">
            <div class="card-body d-flex align-items-end position-relative">
              <div class="pdf-container mx-auto">
                <canvas id="pdf-canvas"></canvas>
              </div>
              <button
                class="btn btn-primary bg-download-btn position-absolute bottom-0 end-0 d-flex justify-content-center align-items-center mb-3 me-4"
                (click)="downloadPDF()"
                type="button"
              >
                <img src="assets/download.svg" class="custom-size" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <button
        class="btn btn-primary bg-ligth-mode ps-5 mt-5 pt-2 pb-2 pe-5"
        type="submit"
        [disabled]="isDisabled()"
      >
        Guardar cambios
      </button>
    </div>
    <div class="d-flex justify-content-center"></div>
    <label class="clickable text-ligth-mode fw-bolder mt-3" (click)="close()">
      Cancelar
    </label>
  </form>
</div>

<div class="modal-header">
  <div class="d-flex justify-content-between align-items-center border-0 w-100">
    <h5 class="modal-title text-color fw-bold mt-3 mx-auto">
      Respuestas del cliente
    </h5>
    <button type="button" class="btn-close ms-0" (click)="modalRef.hide()">
      <span aria-hidden="true" class="visually-hidden text-color">&times;</span>
    </button>
  </div>
</div>
<div class="modal-body border-0 mt-0 pt-0">
  <div class="card shadow border-0 mt-0 mb-3 w-100 dashboard-card-bg">
    <div class="card-title"></div>
    <div class="card-body d-flex flex-column align-items-center">
      <div class="d-flex justify-content-between w-100">
        <div class="d-flex flex-column text-center align-items-center">
          <label>Tipo de producto:</label>
          <img
            [src]="
              survey.type_product === productType.TANK
                ? 'assets/tank.svg'
                : 'assets/cilinder.svg'
            "
            class="product-type-icon-size my-2"
          />
          <h6 class="fw-bolder text-color-primary order-date-text">
            {{ survey.type_product }}
          </h6>
        </div>
        <div class="d-flex flex-column text-center align-items-center">
          <label>Capacidad</label>
          <h6 class="fw-bolder mt-4">{{ survey.capacity }}</h6>
        </div>
        <div class="d-flex flex-column text-center align-items-center">
          <label>Cantidad</label>
          <h6 class="fw-bolder mt-4">{{ survey.quantity }}</h6>
        </div>
        <div class="d-flex flex-column text-center align-items-center">
          <label>Metodo de pago</label>
          <h6 class="fw-bolder mt-4">{{ survey.payment }}</h6>
        </div>
      </div>
      <hr class="mt-3" />
      <div class="px-3 d-flex flex-column justify-content-start w-100">
        <label class="align-text-top">Repuestas del cliente: </label>
        <div class="card border-0">
          <div class="card-body comment-background more-rounded">
            <div *ngFor="let question of questions; let i = index">
              <div *ngIf="i > 0">
                <p class="mb-0">{{ question }}</p>
                <p>{{ survey.poll_answers[i].comment }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

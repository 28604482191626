import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../auth/auth.service';
import { ModalService } from './modal.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  constructor(
    private toastr: ToastrService,
    private router: Router,
    private authService: AuthService,
    private modalService: ModalService
  ) {}

  handleError(error: HttpErrorResponse) {
    console.log('ERROR SERVICE');
    if (error.error instanceof ErrorEvent) {
      this.toastr.error('Ocurrio un error en el servidor:', '¡Error!');
    } else {
      switch (error.status) {
        case 400:
          this.toastr.error(
            'Solicitud incorrecta: ' + error.error.message,
            '¡Error-400!'
          );
          break;
        case 401:
          this.toastr.error(
            'No autorizado: ' + error.error.message,
            '¡Error-401!'
          );
          this.authService.removeSession();
          this.modalService.closeAllModals();
          this.toastr.warning('Inicie sesión de nuevo', '¡Alto!');
          this.router.navigate(['/login']);
          break;
        case 403:
          this.toastr.error('Prohibido: ' + error.error.message, '¡Error-403!');
          break;
        case 404:
          this.toastr.error(
            'No encontrado: ' + error.error.message,
            '¡Error 404!'
          );
          break;
        case 500:
          console.log(error.status);
          if (error.error.errors) {
            for (const key in error.error.errors) {
              if (error.error.errors.hasOwnProperty(key)) {
                const messages = error.error.errors[key];
                messages.forEach((message: string) => {
                  this.toastr.error(message, '¡Error!');
                });
                return;
              }
            }
          }
          if (error.error.message) {
            this.toastr.error(error.error.message, '¡Error!');
            return;
          }
          break;
        default:
          this.toastr.error('Se ha producido un error inesperado', '¡Error!');
          break;
      }
    }
  }
}

import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function passwordContainsLetterAndNumber(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value as string;
    const hasLetter = /[A-Za-zñÑ]/.test(value || '');
    const hasNumber = /\d/.test(value || '');
    const isValid = hasLetter && hasNumber;

    return isValid ? null : { containsLetterAndNumber: true };
  };
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ENDPOINT } from '@constants';
import { ExportType, UserType } from '@enum';
import { Address, FiscalData, UsersFilter } from '@interfaces';
import { UserCreateDto } from 'app/core/dtos/user-create.dto';
import { UserUpdateDto } from 'app/core/dtos/user-update.dto';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}

  filtered({
    page,
    name,
    number,
    user_type,
    state,
  }: UsersFilter): Observable<any> {
    return this.http
      .get<any>(ENDPOINT.USER.PAGINATED(page, user_type, number, name, state))
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  export(format: ExportType, type: string, filters: any): Observable<any> {
    return this.http.get<any>(ENDPOINT.USER.EXPORT(type, format, filters)).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  updateUser(userData: UserUpdateDto, userId: number): Observable<any> {
    return this.http.put<any>(ENDPOINT.USER.UPDATE_USER(userId), userData).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  toggleDarkMode(mode: number, userId: number): Observable<any> {
    return this.http
      .put<any>(ENDPOINT.USER.TOOGLE_THEME(userId), { dark_mode: mode })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  createUser(userData: UserCreateDto): Observable<any> {
    return this.http.post<any>(ENDPOINT.USER.STORE, userData).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  changeStatus(enabled: number, id: number): Observable<any> {
    return this.http
      .put<any>(ENDPOINT.USER.CHANGE_STATUS(id), { enabled })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  updateAddress(addressData: Address, addressId: number): Observable<any> {
    return this.http
      .put<any>(ENDPOINT.USER.UPDATE_ADDRESS(addressId), addressData)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  updateFiscalData(fiscalData: FiscalData): Observable<any> {
    return this.http
      .post<any>(ENDPOINT.USER.UPDATE_FISCAL_DATA, fiscalData)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getRecord(name: string): Observable<Blob> {
    return this.http.get<any>(ENDPOINT.USER.GET_RECORD(name), {
      responseType: 'blob' as 'json',
    });
  }
}

<app-navbar [title]="'Usuarios'"></app-navbar>
<app-tab
  [tabs]="tabs"
  [activeTab]="activeTab"
  (tabChange)="onTabChange($event)"
  class="mb-3"
></app-tab>
<div [ngSwitch]="activeTab" class="mb-3">
  <app-user-list *ngSwitchCase="tabNames.COLABORATORS"></app-user-list>
  <app-client-list *ngSwitchCase="tabNames.CLIENTS"></app-client-list>
</div>

import { Injectable } from '@angular/core';
import { ExportType } from '@enum';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor() {}

  public downloadFile(url: string, name: string): void {
    const a = document.createElement('a');
    a.href = url;
    a.download = name;
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}

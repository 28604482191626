import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

export const changePasswordGuard: CanActivateFn = async (route, state) => {
  const router = inject(Router);
  const authService = inject(AuthService);
  const toastr = inject(ToastrService);

  const token = route.paramMap.get('token');
  if (!token) {
    router.navigate(['/recover-password']);
    return false;
  }
  authService.setToken(token);
  await authService.validateToken(token).subscribe(({ code }: any) => {
    if (code !== 200) {
      router.navigate(['/recover-password']);
      toastr.error('Token inválido, intenta otra vez', '¡Error!');
      return;
    }
  });
  toastr.success(`¡Token válido!`, '¡Éxito!');
  return true;
};

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ENDPOINT } from '@constants';
import { Password, User } from '@interfaces';
import { UserUpdateDto } from 'app/core/dtos/user-update.dto';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  errorService: any;
  constructor(private http: HttpClient) {}

  updateUser(userData: UserUpdateDto, userId: number): Observable<any> {
    return this.http.put<any>(ENDPOINT.USER.UPDATE_USER(userId), userData).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  updatePassword(passwordData: Password): Observable<any> {
    return this.http
      .post<any>(ENDPOINT.USER.CHANGE_PASSWORD, passwordData)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  updateProfilePicture(image: File, userId: number): Observable<any> {
    const formData = new FormData();
    formData.append('image', image, image.name);
    return this.http
      .post<any>(ENDPOINT.USER.SET_PROFILE_PICTURE(userId), formData)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
}

import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';

export const roleGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  const expectedRoles = route.data['expectedRole'] as string[];

  if (authService.isFinance()) {
    if (expectedRoles.length === 0 || !expectedRoles.includes('finance_role')) {
      router.navigate(['/orders']);
      return false;
    }
  }

  if (authService.hasRoles(expectedRoles)) {
    return true;
  } else {
    router.navigate(['/dashboard']);
    return false;
  }
};

<div class="container-fluid ps-0 pe-3 mt-0 w-100 app-bg">
  <div class="card shadow border-0 card-bg">
    <div class="card-body">
      <div class="d-flex justify-content-between">
        <div class="filter-container filter-container-50">
          <div class="input-group d-flex align-items-center">
            <span class="input-group-text bg-transparent">
              <img src="assets/user-dark.svg" alt="unlock" />
            </span>
            <input
              id="start-date"
              type="text"
              class="form-control form-control p-1"
              placeholder="# de empleado"
              [(ngModel)]="filters.number"
            />
          </div>
          <div class="input-group d-flex align-items-center">
            <input
              id="name"
              type="text"
              class="form-control form-control p-1"
              placeholder="Nombre"
              [(ngModel)]="filters.name"
            />
          </div>
          <div class="btn-group d-flex">
            <button
              type="button"
              class="btn btn-primary bg-ligth-mode-2 d-flex align-items-center justify-content-center"
              (click)="resetFilters()"
            >
              <img src="/assets/broom.svg" alt="arrow" />
            </button>
            <button
              type="button"
              class="btn btn-secondary bg-ligth-mode d-flex align-items-center justify-content-center"
              (click)="filter()"
            >
              <img src="/assets/search.svg" alt="arrow" />
            </button>
          </div>
        </div>
        <button
          class="btn btn-solid-bg btn-rounded-custom px-5"
          (click)="openCreateModal()"
        >
          Agregar usuario
        </button>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid ps-0 pe-3 mt-0 pt-4 w-100">
  <div class="card shadow border-0 card-bg">
    <div class="card-body p-0">
      <ngx-datatable
        #userTable
        class="material card-bg"
        [columnMode]="'flex'"
        [headerHeight]="50"
        [footerHeight]="50"
        [columns]="columns"
        rowHeight="auto"
        [rows]="userList"
        [count]="itemCount"
        [externalPaging]="true"
        [offset]="filters.page"
        [limit]="15"
        (page)="setPage($event)"
        [loadingIndicator]="isLoading"
        [messages]="messages"
        (sort)="onSort($event)"
      >
        <ng-template #userData let-row="row">
          <div class="d-flex justify-content-center align-items-center">
            <button
              class="btn btn-primary bg-ligth-mode btn-size-40 d-flex justify-content-center align-items-center"
              (click)="openEditModal(row)"
            >
              <img src="assets/edit.svg" />
            </button>
          </div>
        </ng-template>
        <ng-template #changeStatusButton let-row="row">
          <div class="d-flex justify-content-center align-items-center">
            <button
              class="btn btn-text-color border-0"
              [ngClass]="
                row.enabled == 1 ? 'status-canceled-bg' : 'status-finished-bg'
              "
              (click)="changeStatus(row, row.enabled == 1 ? 0 : 1)"
            >
              {{ row.enabled == 1 ? "Deshabilitar" : "Habilitar" }}
            </button>
          </div>
        </ng-template>
      </ngx-datatable>
      <div class="d-flex flex-colum align-items-center justify-content-end p-3">
        <button
          class="btn btn-solid-2-bg m-2 w-25"
          type="button"
          (click)="export(exportType.EXCEL)"
        >
          Exportar EXCEL
        </button>
        <button
          class="btn btn-solid-2-bg m-2 w-25"
          type="button"
          (click)="export(exportType.PDF)"
        >
          Exportar PDF
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #edit>
  <app-edit-user-modal
    [modalRef]="modalRef"
    [user]="selectedUser"
    (userUpdated)="userUpdated($event)"
  />
</ng-template>

<ng-template #create>
  <app-new-user-modal [modalRef]="modalRef" (userCreated)="userCreated()" />
</ng-template>

import { Component, TemplateRef, ViewChild } from '@angular/core';
import { ExportModules, ExportType, UserType } from '@enum';
import {
  Address,
  ClientListItem,
  ClientsFilter,
  FiscalData,
} from '@interfaces';
import { UserService } from '../../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FileService } from 'app/core/services/file.service';
import { ModalService } from 'app/core/services/modal.service';

@Component({
  selector: 'app-client-list',
  templateUrl: './client-list.component.html',
  styleUrl: './client-list.component.scss',
})
export class ClientListComponent {
  @ViewChild('billingData', { static: true }) billingData!: TemplateRef<any>;
  @ViewChild('clientData', { static: true }) clientData!: TemplateRef<any>;
  @ViewChild('changeStatusButton', { static: true })
  changeStatusButton!: TemplateRef<any>;
  @ViewChild('addressData', { static: true }) addressData!: TemplateRef<any>;
  @ViewChild('edit') edit!: TemplateRef<any>;
  @ViewChild('address') address!: TemplateRef<any>;
  @ViewChild('billing') billing!: TemplateRef<any>;

  @ViewChild('clientList') table: any;

  modalRef!: BsModalRef;
  filters: ClientsFilter;
  number: number | undefined;
  name: string | undefined;
  isLoading: boolean = false;
  userList: ClientListItem[] = [];
  exportModule: ExportModules = ExportModules.USERS;
  type: string = UserType.CLIENTE;
  itemCount: number = 0;
  columns: any[] = [];
  selectedClient!: ClientListItem;
  addresses!: Address[];
  selectedBilling!: FiscalData;
  exportType = ExportType;

  messages = {
    emptyMessage: 'Sin resultados',
    totalMessage: 'Total',
    selectedMessage: 'Seleccionado',
  };

  constructor(
    private userService: UserService,
    private toastr: ToastrService,
    private fileService: FileService,
    private modalService: ModalService
  ) {
    this.filters = {
      page: 0,
      user_type: UserType.CLIENTE,
      name: '',
      number: '',
      state: 'all',
    };
  }

  ngOnInit() {
    this.getUserList();
    this.initTableConfig();
  }

  getUserList = async (): Promise<any> => {
    this.isLoading = true;
    this.userService
      .filtered({ ...this.filters })
      .subscribe(({ code, data, count }): any => {
        if (code === 500) {
          this.isLoading = false;
          return this.toastr.error(
            'No se pudo obtener el historial de precios',
            '¡Error!'
          );
        }
        this.userList = data;
        this.itemCount = count;
        this.isLoading = false;
      });
  };

  resetFilters = (): void => {
    this.filters = {
      page: 0,
      user_type: UserType.CLIENTE,
      name: '',
      number: undefined,
      state: 'all',
    };
  };

  public setPage = async (pageInfo: any): Promise<any> => {
    this.filters.page = pageInfo.offset;
    this.getUserList();
  };

  initTableConfig = (): void => {
    this.columns = [
      {
        name: '# Cliente',
        prop: 'number',
        flexGrow: 1,
        resizeable: false,
        draggable: false,
        comparator: this.customComparator.bind(this),
      },
      {
        name: 'Nombre',
        prop: 'complete_name',
        flexGrow: 1,
        resizeable: false,
        draggable: false,
      },
      {
        name: 'Ver dirección',
        flexGrow: 1,
        resizeable: false,
        draggable: false,
        sortable: false,
        cellTemplate: this.addressData,
      },
      {
        name: 'Correo',
        prop: 'email',
        flexGrow: 1,
        resizeable: false,
        draggable: false,
      },
      {
        name: 'Teléfono',
        prop: 'phone',
        flexGrow: 1,
        resizeable: false,
        draggable: false,
      },
      {
        name: 'Municipio',
        prop: 'municipality',
        flexGrow: 1,
        resizeable: false,
        draggable: false,
      },
      {
        name: 'Estado',
        prop: 'state',
        flexGrow: 1,
        resizeable: false,
        draggable: false,
      },
      {
        name: 'Ver datos de<br>facturación',
        flexGrow: 1,
        resizeable: false,
        draggable: false,
        sortable: false,
        cellTemplate: this.billingData,
      },
      {
        name: 'Editar',
        flexGrow: 1,
        resizeable: false,
        draggable: false,
        sortable: false,
        cellTemplate: this.clientData,
      },
      {
        name: 'Acciones',
        flexGrow: 1.4,
        resizeable: false,
        draggable: false,
        sortable: false,
        cellTemplate: this.changeStatusButton,
      },
    ];
  };

  openEditModal(user: any) {
    this.selectedClient = user;
    this.modalRef = this.modalService.openModal(
      this.edit,
      'modal-lg modal-rounded'
    );
  }

  openAddressModal(user: any) {
    this.addresses = user.addresses;
    this.modalRef = this.modalService.openModal(
      this.address,
      'modal-lg modal-rounded'
    );
  }

  openBillingModal(user: any) {
    this.selectedBilling = user.fiscal_data[0];
    this.modalRef = this.modalService.openModal(
      this.billing,
      'modal-lg modal-rounded'
    );
  }

  changeStatus = async (
    user: ClientListItem,
    enabled: number
  ): Promise<any> => {
    this.isLoading = true;
    this.userService
      .changeStatus(enabled, user.id)
      .subscribe(({ code, data }): any => {
        if (code === 500) {
          this.isLoading = false;
          return this.toastr.error(
            'No se pudo actualizar el estatus del usuario',
            '¡Error!'
          );
        }
        this.getUserList();
        this.isLoading = false;
        this.toastr.success('¡Estatus actualizado correctamente', '¡Éxito!');
        return;
      });
  };

  export = async (format: ExportType): Promise<any> => {
    this.userService
      .export(format, this.type, this.filters)
      .subscribe(({ code, data }: any) => {
        if (code === 500) {
          return this.toastr.error('No se pudo generar el archivo', '¡Error!');
        }
        this.toastr.success('¡Archivo descargado!', '¡Éxito!');
        this.fileService.downloadFile(data.url, data.name);
        return;
      });
  };

  filter = (): void => {
    this.filters.page = 0;
    this.getUserList();
  };

  customComparator(propA: string, propB: string): number {
    const numA = parseInt(propA.match(/\d+/)?.[0] || '0', 10);
    const numB = parseInt(propB.match(/\d+/)?.[0] || '0', 10);

    if (numA === numB) {
      return propA.localeCompare(propB);
    }

    return numA - numB;
  }

  onSort(event: any) {
    this.table.offset = this.filters.page;
  }
}

<div class="modal-header d-flex justify-content-center border-0">
  <h4 class="modal-title text-color fw-bold mt-3">Editar información</h4>
</div>
<div class="modal-body text-center pt-0">
  <p class="fw-bold text-color-2">Ingresa los datos correspondientes</p>
  <form [formGroup]="editUserForm" (ngSubmit)="onSubmit()" class="w-100 pt-2">
    <div
      class="container d-flex flex-column justify-content-center align-items-center"
    >
      <div class="form-group w-75 mb-3">
        <div class="form-floating">
          <input
            id="number"
            type="text"
            class="form-control"
            placeholder="Número de empleado"
            formControlName="number"
          />
          <label for="number">Número de empleado</label>
        </div>
        <div class="text-danger ps-5 pt-1">
          {{ numberErrors }}
        </div>
      </div>
      <div class="form-group w-75 mb-3">
        <div class="form-floating">
          <input
            id="name"
            type="text"
            class="form-control"
            placeholder="Nombre"
            formControlName="complete_name"
            (input)="onInput($event)"
            (paste)="onPaste($event)"
          />
          <label for="name">Nombre completo</label>
        </div>
        <div class="text-danger ps-5 pt-1">
          {{ completeNameErrors }}
        </div>
      </div>
      <div class="form-group w-75 mb-3">
        <div class="form-floating">
          <input
            id="email"
            type="email"
            class="form-control"
            placeholder="Correo electrónico"
            formControlName="email"
          />
          <label for="email">Correo electrónico</label>
        </div>
        <div class="text-danger ps-4 pt-1">
          {{ emailErrors }}
        </div>
      </div>
      <div class="form-group w-75 mb-3 d-flex flex-column align-items-start">
        <label class="text-color-2">¿Asignar como usuario administrador?</label>
        <div>
          <input
            type="radio"
            formControlName="admin_role"
            value="1"
            id="adminYes"
            class="form-check-input me-2"
          />
          <label for="adminYes" class="form-check-label me-5 text-color"
            >Sí</label
          >
          <input
            type="radio"
            formControlName="admin_role"
            value="null"
            id="adminNo"
            class="form-check-input me-2"
          />
          <label for="adminNo" class="form-check-label text-color">No</label>
        </div>
      </div>
      <div class="form-group w-75 d-flex flex-column align-items-start">
        <label class="text-color-2"
          >¿Asignar como usuario de facturación?</label
        >
        <div>
          <input
            type="radio"
            formControlName="finance_role"
            value="1"
            id="financeYes"
            class="form-check-input me-2"
          />
          <label for="financeYes" class="form-check-label me-5 text-color"
            >Sí</label
          >
          <input
            type="radio"
            formControlName="finance_role"
            value="null"
            id="financeNo"
            class="form-check-input me-2"
          />
          <label for="financeNo" class="form-check-label text-color">No</label>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <button
        class="btn btn-primary bg-ligth-mode mt-5 pt-2 pb-2 ps-5 pe-5"
        type="submit"
        [disabled]="isDisabled()"
      >
        Guardar cambios
      </button>
    </div>
    <div class="d-flex justify-content-center"></div>
    <label class="clickable text-ligth-mode fw-bolder mt-3" (click)="close()">
      Cancelar
    </label>
  </form>
</div>

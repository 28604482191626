export const taxSystemFisica = [
  'Sueldos y Salarios e Ingresos Asimilados a Salarios',
  'Arrendamiento',
  'Régimen de Enajenación o Adquisición de Bienes',
  'Demás ingresos',
  'Consolidación',
  'Ingresos por Dividendos (socios y accionistas)',
  'Personas Físicas con Actividades Empresariales y Profesionales',
  'Ingresos por intereses',
  'Régimen de los ingresos por obtención de premios',
  'Sin obligaciones fiscales',
  'Incorporación Fiscal',
  'Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas',
  'Régimen Simplificado de Confianza',
  'Hidrocarburos',
  'De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales',
  'Enajenación de acciones en bolsa de valores',
];

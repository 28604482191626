<div class="modal-header d-flex justify-content-center border-0">
  <h4 class="modal-title text-color fw-bold mt-3">Asignar estatus de pago</h4>
</div>
<div class="modal-body text-center pt-0">
  <form
    [formGroup]="paymentStatusForm"
    (ngSubmit)="onSubmit()"
    class="w-100 pt-2"
  >
    <div
      class="container d-flex flex-column justify-content-center align-items-center"
    >
      <div class="form-group w-75">
        <div class="form-floating">
          <select
            id="estatus_payment"
            class="form-select"
            formControlName="estatus_payment"
          >
            <option [value]="paymentStatus.PAID">
              {{ paymentStatus.PAID }}
            </option>
            <option [value]="paymentStatus.PENDING_PAYMENT">
              {{ paymentStatus.PENDING_PAYMENT }}
            </option>
            <option [value]="paymentStatus.WITHOUT_PAYMENT">
              {{ paymentStatus.WITHOUT_PAYMENT }}
            </option>
          </select>
          <label for="state">Estatus de pago</label>
        </div>
        <div class="text-danger pt-1">
          {{ paymentStatusErrors }}
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <button
        class="btn btn-primary bg-ligth-mode ps-5 mt-5 pt-2 pb-2 pe-5"
        type="submit"
        [disabled]="paymentStatusForm.invalid || submitted"
      >
        Confirmar
      </button>
    </div>
    <div class="d-flex justify-content-center"></div>
    <label class="clickable text-ligth-mode fw-bolder mt-3" (click)="close()">
      Cancelar
    </label>
  </form>
</div>

<div class="container-fluid">
  <div class="row">
    <div class="login-logo-bg col-md-4 d-flex flex-column full-height p-0 m-0">
      <div class="mt-auto">
        <img
          [src]="
            isDarkMode
              ? '/assets/gas-lago-letters-dark.svg'
              : '/assets/gas-lago-letters.svg'
          "
          alt="gas-lago"
          class="img-small mx-auto d-block"
        />
      </div>
      <div class="mt-auto">
        <img
          src="/assets/gas-lago-login-house.svg"
          alt="house"
          class="img-medium"
        />
      </div>
    </div>
    <div
      class="col-md-8 d-flex flex-column align-items-center justify-content-center app-bg"
    >
      <img
        [src]="
          isDarkMode
            ? '/assets/gas-lago-login-dark.svg'
            : '/assets/gas-lago-login.svg'
        "
        alt="login"
        class="login mb-5"
      />
      <form
        [formGroup]="loginForm"
        (ngSubmit)="onSubmit()"
        class="w-75 d-flex flex-column"
      >
        <div
          class="form-group d-flex flex-column align-items-center justify-content-center"
        >
          <div class="input-group m-2 w-50">
            <input
              type="text"
              class="form-control border-end-0"
              placeholder="Número de empleado"
              formControlName="number"
            />
            <span class="input-group-text bg-transparent">
              <img src="assets/user.svg" alt="user" />
            </span>
          </div>
          <div
            *ngIf="
              loginForm.get('number')?.dirty || loginForm.get('number')?.touched
            "
          >
            <div class="text-danger m-2">
              {{ employeeErrors }}
            </div>
          </div>
        </div>
        <div
          class="form-group d-flex flex-column align-items-center justify-content-center"
        >
          <div class="input-group m-2 w-50">
            <input
              [type]="isVisible ? 'text' : 'password'"
              class="form-control border-end-0"
              placeholder="Contraseña"
              formControlName="password"
            />
            <span
              (click)="changePasswordVisibility()"
              class="input-group-text bg-transparent clickable"
            >
              <img
                [src]="
                  isVisible ? 'assets/lock-slash.svg' : 'assets/unlock.svg'
                "
                alt="unlock"
              />
            </span>
          </div>
          <div
            *ngIf="
              loginForm.get('password')?.dirty ||
              loginForm.get('password')?.touched
            "
          >
            <div class="text-danger">
              {{ passwordErrors }}
            </div>
          </div>
        </div>
        <div class="d-flex w-75 justify-content-end ms-3">
          <a
            [routerLink]="['/recover-password']"
            class="m-2 link-underline link-underline-opacity-0 text-color"
            >¿Olvidaste tu contraseña?</a
          >
        </div>
        <div class="d-flex w-100 justify-content-center">
          <button
            class="btn btn-primary m-2 w-50 btn-solid-bg"
            type="submit"
            [disabled]="!loginForm.valid || submitted"
          >
            Iniciar sesión
            <img
              class="ms-4"
              [src]="
                isDarkMode ? '/assets/arrow-dark.svg' : '/assets/arrow.svg'
              "
              alt="arrow"
            />
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

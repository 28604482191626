<div class="modal-header d-flex justify-content-center border-0">
  <h4 class="modal-title text-color fw-bold mt-3">Cargar factura</h4>
</div>
<div class="modal-body text-center pt-0">
  <div
    class="container d-flex flex-column justify-content-center align-items-center"
  >
    <div class="form-group">
      <label for="pdfInput" class="text-color-2">Subir archivo PDF:</label>
      <input
        type="file"
        (change)="onPDFChange($event)"
        id="pdfInput"
        class="form-control"
      />
    </div>

    <div class="form-group">
      <label for="xmlInput" class="text-color-2">Subir archivo XML:</label>
      <input
        type="file"
        (change)="onXMLChange($event)"
        id="xmlInput"
        class="form-control"
      />
    </div>
    <div class="d-flex justify-content-center">
      <button
        class="btn btn-primary bg-ligth-mode ps-5 mt-5 pt-2 pb-2 pe-5"
        type="submit"
        [disabled]="!pdf || !xml || submitted"
        (click)="uploadBill()"
      >
        Confirmar
      </button>
    </div>
    <div class="d-flex justify-content-center"></div>
    <label class="clickable text-ligth-mode fw-bolder mt-3" (click)="close()">
      Cancelar
    </label>
  </div>
</div>

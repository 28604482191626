import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private darkTheme = new BehaviorSubject<boolean>(false);
  darkTheme$ = this.darkTheme.asObservable();

  setDarkTheme(isDark: boolean) {
    this.darkTheme.next(isDark);
    if (isDark) {
      document.documentElement.classList.add('dark-theme');
      localStorage.setItem('$theme', 'dark');
    } else {
      document.documentElement.classList.remove('dark-theme');
      localStorage.removeItem('$theme');
    }
  }
}

<div
  class="navbar rounded mt-1 ms-1 me-3 shadow-sm d-flex justify-content-between align-items-center card-bg"
>
  <h4 class="navbar-title nav-text fw-bold">{{ title }}</h4>
  <div class="d-flex">
    <div class="dropdown">
      <div
        class="navbar-right d-flex justify-content-between align-items-center dropdown-toggle"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <div class="navbar-text">
          <div class="text-end">
            <h5 class="mb-0 truncate-text fw-bolder nav-text">
              {{ userData?.complete_name }}
            </h5>
            <small class="nav-text">{{ userData?.type_user }}</small>
          </div>
        </div>

        <img
          [src]="imageSrc || 'assets/user-profile-picture-black.svg'"
          class="img-round"
          alt=""
        />
      </div>
      <ul class="dropdown-menu dropdown-menu-end shadow p-0 me-5 mt-1 border-0">
        <li>
          <a class="dropdown-item m-0" routerLink="/profile"
            ><img
              class="img-dropdown pe-2"
              [src]="
                isDarkMode
                  ? '/assets/user-profile-picture-light-2.svg'
                  : '/assets/user-profile-picture-light.svg'
              "
              alt=""
            />Mi perfil</a
          >
        </li>
        <li>
          <a class="dropdown-item m-0" (click)="logout()"
            ><img
              class="img-dropdown pe-1"
              alt=""
              [src]="
                isDarkMode
                  ? '/assets/logout-light-2.svg'
                  : '/assets/logout-light.svg'
              "
            />Cerrar sesión</a
          >
        </li>
      </ul>
    </div>
    <div
      class="form-check form-switch position-relative d-flex justify-content-center align-items-center w-100 ps-0 me-3"
    >
      <div class="switch">
        <input
          type="checkbox"
          id="themeSwitch"
          class="switch-input"
          (change)="toggleTheme(switchMode, true)"
          [(ngModel)]="switchMode"
        />
        <label for="themeSwitch" class="switch-label">
          <span class="switch-icon sun-icon"></span>
          <span class="switch-icon moon-icon"></span>
        </label>
      </div>
      <button
        class="btn btn-dark bg-dark-ligh-mode btn-size-40 d-flex justify-content-center align-items-center ms-3"
        (click)="expandScreen()"
      >
        <img src="assets/maximize.svg" />
      </button>
    </div>
  </div>
</div>

import { HttpInterceptorFn } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { inject } from '@angular/core';

export const httpAuthInterceptor: HttpInterceptorFn = (req, next) => {
  const authservice = inject(AuthService);
  const authToken = authservice.getToken();

  const authReq = req.clone({
    headers: req.headers.set('Authorization', `Bearer ${authToken}`),
  });

  return next(authReq);
};

import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { DatePipe } from '@angular/common';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ToastrModule } from 'ngx-toastr';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { setTheme } from 'ngx-bootstrap/utils';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { esLocale } from 'ngx-bootstrap/locale';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './features/login/login.component';
import { DashboardComponent } from './features/dashboard/dashboard.component';
import { ProfileComponent } from './features/profile/profile.component';
import { UsersComponent } from './features/users/users.component';
import { OrdersComponent } from './features/orders/orders.component';
import { ReportsComponent } from './features/reports/reports.component';
import { PricingComponent } from './features/pricing/pricing.component';
import { RecoverPasswordComponent } from './features/recover-password/recover-password.component';
import { httpErrorInterceptor } from './core/services/http-error.interceptor';
import { ErrorService } from './core/services/error.service';
import { ChangePasswordComponent } from './features/change-password/change-password.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { MainLayoutComponent } from './shared/main-layout/main-layout.component';
import { AuthLayoutComponent } from './shared/auth-layout/auth-layout.component';
import { httpAuthInterceptor } from './core/services/http-auth.interceptor';
import { CylinderPricingComponent } from './features/pricing/components/cilinder-pricing/cylinder-pricing.component';
import { PriceHistoryComponent } from './features/pricing/components/price-history/price-history.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { UserListComponent } from './features/users/components/user-list/user-list.component';
import { ClientListComponent } from './features/users/components/client-list/client-list.component';
import { TabComponent } from './shared/tab/tab.component';
import { ExportButtonsComponent } from './shared/export-buttons/export-buttons.component';
import { EditUserModalComponent } from './shared/modals/edit-user/edit-user-modal.component';
import { NewUserModalComponent } from './shared/modals/new-user/new-user-modal.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { EditClientModalComponent } from './shared/modals/edit-client/edit-client-modal.component';
import { EditClientAddressModalComponent } from './shared/modals/edit-client-address-modal/edit-client-address-modal.component';
import { EditClientBillingModalComponent } from './shared/modals/edit-client-billing-modal/edit-client-billing-modal.component';
import { HasRoleDirective } from './core/directives/has-role.directive';
import { SetOrderStatusModalComponent } from './shared/modals/set-order-status/set-order-status-modal.component';
import { SetPaymentStatusModalComponent } from './shared/modals/set-payment-status/set-payment-status-modal.component';
import { SetCommentsModalComponent } from './shared/modals/set-comments/set-comments-modal.component';
import { SetFolioModalComponent } from './shared/modals/set-folio/set-folio-modal.component';
import { OrderDetailsModalComponent } from './shared/modals/order-details/order-details-modal.component';
import { SurveysComponent } from './features/reports/components/surveys/surveys.component';
import { StarClientComponent } from './features/reports/components/star-client/star-client.component';

import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { SurveyDetailsModalComponent } from './shared/modals/survey-details/survey-details-modal.component';
import { UploadBillModalComponent } from './shared/modals/upload-bill/upload-bill-modal.component';
import { ChangeAmountModalComponent } from './shared/modals/change-amount/change-amount-modal.component';

defineLocale('es', esLocale);
registerLocaleData(localeEs, 'es');

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    ProfileComponent,
    UsersComponent,
    OrdersComponent,
    ReportsComponent,
    PricingComponent,
    RecoverPasswordComponent,
    ChangePasswordComponent,
    NavbarComponent,
    SidebarComponent,
    MainLayoutComponent,
    AuthLayoutComponent,
    CylinderPricingComponent,
    PriceHistoryComponent,
    UserListComponent,
    ClientListComponent,
    TabComponent,
    ExportButtonsComponent,
    EditUserModalComponent,
    NewUserModalComponent,
    EditClientModalComponent,
    EditClientAddressModalComponent,
    EditClientBillingModalComponent,
    HasRoleDirective,
    SetOrderStatusModalComponent,
    SetPaymentStatusModalComponent,
    SetCommentsModalComponent,
    SetFolioModalComponent,
    OrderDetailsModalComponent,
    SurveysComponent,
    StarClientComponent,
    SurveyDetailsModalComponent,
    UploadBillModalComponent,
    ChangeAmountModalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    FontAwesomeModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      closeButton: true,
    }),
    NgxDatatableModule,
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    NgSelectModule,
  ],
  providers: [
    provideHttpClient(
      withInterceptors([httpErrorInterceptor, httpAuthInterceptor])
    ),
    DatePipe,
    { provide: LOCALE_ID, useValue: 'es' },
    ErrorService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    setTheme('bs5');
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ENDPOINT } from '@constants';
import { ExportType } from '@enum';
import { StartClientFilters, SurveyFilter } from '@interfaces';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ReportsService {
  constructor(private http: HttpClient) {}

  filteredSurveys({
    page,
    number,
    user_name,
    end_date,
    start_date,
  }: SurveyFilter): Observable<any> {
    return this.http
      .get<any>(
        ENDPOINT.REPORTS.PAGINATED_SURVEYS(
          page,
          number,
          user_name,
          end_date,
          start_date
        )
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  exportSurveys(format: ExportType, filters: any): Observable<any> {
    return this.http
      .get<any>(ENDPOINT.REPORTS.EXPORT_SURVEYS(format, filters))
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  filteredStarClients({
    page,
    month,
    year,
  }: StartClientFilters): Observable<any> {
    return this.http
      .get<any>(ENDPOINT.REPORTS.PAGINATED_STAR_CLIENTS(page, month, year))
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  exportStarClients(format: ExportType, filters: any): Observable<any> {
    return this.http
      .get<any>(ENDPOINT.REPORTS.EXPORT_STAR_CLIENTS(format, filters))
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
}

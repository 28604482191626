<div class="d-flex flex-colum align-items-center justify-content-end p-3">
  <button
    class="btn btn-solid-2-bg m-2 w-25"
    type="button"
    (click)="export(exportType.EXCEL)"
  >
    Exportar EXCEL
  </button>
  <button
    class="btn btn-solid-2-bg m-2 w-25"
    type="button"
    (click)="export(exportType.PDF)"
  >
    Exportar PDF
  </button>
</div>

<div class="container-fluid">
  <div class="row justify-content-center align-items-center app-bg">
    <div
      class="col-md-4 vh-100 d-flex flex-column justify-content-center align-items-center"
    >
      <img
        [src]="
          isDarkMode
            ? '/assets/gas-lago-recover-password-dark.svg'
            : '/assets/gas-lago-recover-password.svg'
        "
        alt="gas-lago"
        class="img-medium mb-5"
      />
      <form
        [formGroup]="changePasswordForm"
        (ngSubmit)="onSubmit()"
        class="text-center w-100"
      >
        <div class="form-group mb-4">
          <div class="input-group">
            <input
              [type]="isVisible ? 'text' : 'password'"
              class="form-control border-end-0"
              placeholder="Nueva contraseña"
              formControlName="password"
            />
            <span
              (click)="changePasswordVisibility(true)"
              class="input-group-text bg-transparent clickable"
            >
              <img
                [src]="
                  isVisible ? 'assets/lock-slash.svg' : '/assets/unlock.svg'
                "
                alt="user"
              />
            </span>
          </div>
          <div
            *ngIf="
              changePasswordForm.get('password')?.dirty ||
              changePasswordForm.get('password')?.touched
            "
          >
            <div class="text-danger">
              {{ passwordErrors }}
            </div>
          </div>
        </div>
        <div class="form-group mb-4">
          <div class="input-group">
            <input
              [type]="isVisibleConfirm ? 'text' : 'password'"
              class="form-control border-end-0"
              placeholder="Confirmar contraseña"
              formControlName="confirmPassword"
            />
            <span
              (click)="changePasswordVisibility()"
              class="input-group-text bg-transparent clickable"
            >
              <img
                [src]="
                  isVisibleConfirm
                    ? 'assets/lock-slash.svg'
                    : '/assets/unlock.svg'
                "
                alt="user"
              />
            </span>
          </div>
          <div
            *ngIf="
              changePasswordForm.get('confirmPassword')?.dirty ||
              changePasswordForm.get('confirmPassword')?.touched
            "
          >
            <div class="text-danger">
              {{ confirmPasswordErrors }}
            </div>
          </div>
        </div>
        <button
          class="btn btn-solid-bg w-100 mt-3"
          type="submit"
          [disabled]="changePasswordForm.invalid || submitted"
        >
          Continuar
          <img
            class="ms-4"
            [src]="isDarkMode ? '/assets/arrow-dark.svg' : '/assets/arrow.svg'"
            alt="arrow"
          />
        </button>
      </form>
    </div>
  </div>
</div>

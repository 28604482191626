<div class="body">
  <nav [ngClass]="{ close: isSidebarClosed }" class="sidebar sidebar-bg">
    <header>
      <div class="image-text mt-2">
        <img
          *ngIf="!isSidebarClosed"
          src="assets/gas-lago-full-logo.svg"
          class="image"
          alt=""
        />
        <img
          *ngIf="isSidebarClosed"
          src="assets/gas-lago-icon.svg"
          class="image"
          alt=""
        />
      </div>
      <div
        class="toggle shadow"
        (click)="toggleSidebar()"
        [class.close]="isSidebarClosed"
      >
        <img src="assets/chevron.svg" class="icon-toggle" />
      </div>
    </header>
    <hr class="custom-separator mb-0" />
    <div class="menu-bar">
      <div class="menu">
        <ul class="menu-links ps-0">
          <li class="nav-link" *appHasRole="['admin_role', 'call_center_role']">
            <a routerLink="/dashboard">
              <img src="assets/home.svg" class="icon ms-3 me-3" />
              <!-- <i class="icon icon-arrow-icon ms-3 me-3"></i> -->
              <span class="text nav-text">Inicio</span>
            </a>
          </li>
          <li class="nav-link" *appHasRole="['admin_role']">
            <a routerLink="/users">
              <img src="assets/users.svg" class="icon ms-3 me-3" />
              <span class="text nav-text">Usuarios</span>
            </a>
          </li>
          <li class="nav-link">
            <a routerLink="/orders">
              <img src="assets/orders.svg" class="icon ms-3 me-3" />
              <span class="text nav-text">Pedidos</span>
            </a>
          </li>
          <li class="nav-link" *appHasRole="['admin_role', 'call_center_role']">
            <a routerLink="/reports">
              <img src="assets/reports.svg" class="icon ms-3 me-3" />
              <span class="text nav-text">Reportes</span>
            </a>
          </li>
          <li class="nav-link">
            <a
              routerLink="/pricing"
              *appHasRole="['admin_role', 'call_center_role']"
            >
              <img src="assets/pricing.svg" class="icon ms-3 me-3" />
              <span class="text nav-text"
                >Gestión de <br />precios promedio</span
              >
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</div>

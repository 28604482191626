import { Component, TemplateRef, ViewChild } from '@angular/core';
import { ExportType } from '@enum';
import { StartClientFilters } from '@interfaces';
import { ReportsService } from '../../services/reports.service';
import { ToastrService } from 'ngx-toastr';
import { FileService } from 'app/core/services/file.service';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { esLocale } from 'ngx-bootstrap/locale';
import { DatePipe } from '@angular/common';

defineLocale('es', esLocale);

@Component({
  selector: 'app-star-client',
  templateUrl: './star-client.component.html',
  styleUrl: './star-client.component.scss',
})
export class StarClientComponent {
  @ViewChild('index', { static: true }) index!: TemplateRef<any>;

  @ViewChild('startClientTable') table: any;

  filters: StartClientFilters;
  columns: any[] = [];
  starClientList: any[] = [];
  itemCount: number = 0;
  isLoading: boolean = false;
  exportType = ExportType;
  config: any;

  year = undefined;
  month = undefined;

  constructor(
    private reportsService: ReportsService,
    private toastr: ToastrService,
    private fileService: FileService,
    private localeService: BsLocaleService,
    private datePipe: DatePipe
  ) {
    this.filters = {
      page: 0,
      year: '',
      month: '',
    };
    this.config = {
      dateInputFormat: 'MMMM',
      minMode: 'month',
      containerClass: 'month-datepicker-container',
    };
    this.localeService.use('es');
  }

  messages = {
    emptyMessage: 'Sin resultados',
    totalMessage: 'Total',
    selectedMessage: 'Seleccionado',
  };

  async ngOnInit() {
    this.initTableConfig();
    this.getStarClients();
  }

  getStarClients = async (): Promise<any> => {
    this.isLoading = true;
    if (this.month) {
      const transformedMonth = this.datePipe.transform(this.month, 'MM');
      this.filters.month = transformedMonth || '';
    }
    if (this.year) {
      const transformedYear = this.datePipe.transform(this.year, 'YYYY');
      this.filters.year = transformedYear || '';
    }
    this.reportsService
      .filteredStarClients({ ...this.filters })
      .subscribe(({ data, count, code }): any => {
        if (code === 500) {
          this.isLoading = false;
          return this.toastr.error(
            'No se han podido obtener las clientes estrella',
            '¡Error!'
          );
        }
        this.itemCount = data.length;
        this.isLoading = false;
        this.starClientList = [...data];
        if (data.length > 0) {
          this.starClientList.forEach((starClient, index) => {
            starClient.top = index + 1;
          });
        }
        return;
      });
  };

  export = async (format: ExportType): Promise<any> => {
    this.reportsService
      .exportStarClients(format, this.filters)
      .subscribe(({ code, data }: any) => {
        if (code === 500) {
          return this.toastr.error('No se pudo generar el archivo', '¡Error!');
        }
        this.toastr.success('¡Archivo descargado!', '¡Éxito!');
        this.fileService.downloadFile(data.url, data.name);
        return;
      });
  };

  public setPage = async (pageInfo: any): Promise<any> => {
    this.filters.page = pageInfo.offset;
    this.getStarClients();
  };

  initTableConfig = (): void => {
    this.columns = [
      {
        name: 'Top',
        prop: 'top',
        resizeable: false,
        draggable: false,
        cellTemplate: this.index,
      },
      {
        name: 'Nombre',
        prop: 'user.complete_name',
        resizeable: false,
        draggable: false,
      },
      {
        name: 'Correo electrónico',
        prop: 'user.email',
        resizeable: false,
        draggable: false,
      },
      {
        name: 'Teléfono',
        prop: 'user.phone',
        resizeable: false,
        draggable: false,
        sortable: false,
      },
      {
        name: 'Cantidad de pedidos',
        prop: 'order_count',
        resizeable: false,
        draggable: false,
        sortable: false,
      },
    ];
  };

  resetFilters = (): void => {
    this.filters = {
      month: undefined,
      page: 0,
      year: undefined,
    };

    this.month = undefined;
    this.year = undefined;
  };

  onSort(event: any) {
    this.table.offset = this.filters.page;
  }

  filter() {
    this.filters.page = 0;
    this.getStarClients();
  }

  disableInput(event: KeyboardEvent): void {
    event.preventDefault();
  }
}

import { Component, TemplateRef, ViewChild } from '@angular/core';
import { OrderService } from './services/order.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Address, OrdersFilters } from '@interfaces';
import { DatePipe } from '@angular/common';
import { OrderListItem } from 'app/core/interfaces/order-list-item.interface';
import {
  ExportType,
  OrderStatus,
  PaymentStatus,
  PaymentType,
  ProductType,
} from '@enum';
import { AuthService } from 'app/core/auth/auth.service';
import { FileService } from 'app/core/services/file.service';
import { ModalService } from 'app/core/services/modal.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrl: './orders.component.scss',
})
export class OrdersComponent {
  @ViewChild('address', { static: true }) address!: TemplateRef<any>;
  @ViewChild('billing', { static: true }) billing!: TemplateRef<any>;
  @ViewChild('billSended', { static: true }) billSended!: TemplateRef<any>;
  @ViewChild('employee', { static: true }) employee!: TemplateRef<any>;
  @ViewChild('folioButton', { static: true }) folioButton!: TemplateRef<any>;
  @ViewChild('orderStatusButton', { static: true })
  orderStatusButton!: TemplateRef<any>;
  @ViewChild('paymentStatusButton', { static: true })
  paymentStatusButton!: TemplateRef<any>;
  @ViewChild('setFolio') setFolio!: TemplateRef<any>;
  @ViewChild('setOrderStatus') setOrderStatus!: TemplateRef<any>;
  @ViewChild('setPaymentStatus') setPaymentStatus!: TemplateRef<any>;
  @ViewChild('setComments') setComments!: TemplateRef<any>;
  @ViewChild('details') details!: TemplateRef<any>;
  @ViewChild('bill') bill!: TemplateRef<any>;
  @ViewChild('amount') amount!: TemplateRef<any>;
  filters: OrdersFilters;
  temporaryFilters: OrdersFilters;
  estatus: string = 'all';
  columns: any[] = [];
  orderList: any[] = [];
  itemCount: number = 0;
  isLoading: boolean = false;
  selectedOrder!: OrderListItem;
  modalRef!: BsModalRef;
  exportType = ExportType;

  @ViewChild('orderTable') table: any;

  messages = {
    emptyMessage: 'Sin resultados',
    totalMessage: 'Total',
    selectedMessage: 'Seleccionado',
  };

  constructor(
    private orderService: OrderService,
    private toastr: ToastrService,
    private datePipe: DatePipe,
    private authService: AuthService,
    private fileService: FileService,
    private modalService: ModalService
  ) {
    this.filters = {
      size: 15,
      page: 0,
      statusDone: false,
      statusNew: false,
      statusCanceled: false,
      statusInProgress: false,
      phone: '',
      number: '',
      payment: 'all',
      endDate: undefined,
      startDate: undefined,
      complete_name: '',
      paymentStatus: 'all',
      municipality: '',
      userAdd: '',
      requiresInvoice: undefined,
    };

    this.temporaryFilters = {
      size: 15,
      page: 0,
      statusDone: false,
      statusNew: false,
      statusCanceled: false,
      statusInProgress: false,
      phone: '',
      number: '',
      payment: 'all',
      endDate: undefined,
      startDate: undefined,
      complete_name: '',
      paymentStatus: 'all',
      municipality: '',
      userAdd: '',
      requiresInvoice: undefined,
    };
  }

  searchOrders = async (): Promise<void> => {
    this.filters = { ...this.temporaryFilters, page: 0 };
    await this.getOrders();
  };

  async ngOnInit() {
    this.initTableConfig();
    if (this.authService.hasRole('finance_role')) {
      this.temporaryFilters.requiresInvoice = 1;
      this.filters.requiresInvoice = 1;
    } else if (this.authService.hasRole('call_center_role')) {
      this.temporaryFilters.statusCanceled = true;
      this.temporaryFilters.statusInProgress = true;
      this.temporaryFilters.statusNew = true;
      this.filters.statusCanceled = true;
      this.filters.statusInProgress = true;
      this.filters.statusNew = true;
    }
    await this.getOrders();
  }

  getOrders = async (): Promise<any> => {
    this.isLoading = true;
    try {
      const response = await firstValueFrom(
        this.orderService.filtered({ ...this.filters })
      );
      const { data, count, code } = response;

      if (code === 500) {
        this.isLoading = false;
        this.toastr.error('No se han podido obtener los estatus', '¡Error!');
        return;
      }

      this.itemCount = count;
      this.orderList = [...data];
    } catch (error) {
      this.isLoading = false;
      console.error('Error obteniendo las órdenes:', error);
      this.toastr.error('Error obteniendo las órdenes', '¡Error!');
    } finally {
      this.isLoading = false;
    }
  };

  export = async (format: ExportType): Promise<any> => {
    this.orderService
      .export(format, this.filters)
      .subscribe(({ code, data }: any) => {
        if (code === 500) {
          return this.toastr.error('No se pudo generar el archivo', '¡Error!');
        }
        this.toastr.success('¡Archivo descargado!', '¡Éxito!');
        this.fileService.downloadFile(data.url, data.name);
        return;
      });
  };

  initTableConfig = (): void => {
    this.columns = [
      {
        name: '# Folio',
        prop: 'number',
        resizeable: false,
        draggable: false,
        cellTemplate: this.folioButton,
        flexGrow: 1,
      },
      {
        name: 'Estatus<br>de pedido',
        prop: 'estatus',
        resizeable: false,
        draggable: false,
        cellTemplate: this.orderStatusButton,
        flexGrow: 1,
      },
      {
        name: 'Tipo de pago',
        prop: 'payment',
        resizeable: false,
        draggable: false,
        flexGrow: 1,
      },
      {
        name: 'Estatus<br>de pago',
        prop: 'estatus_payment',
        resizeable: false,
        draggable: false,
        cellTemplate: this.paymentStatusButton,
        flexGrow: 1,
      },
      {
        name: 'Dirección',
        resizeable: false,
        draggable: false,
        sortable: false,
        cellTemplate: this.address,
        flexGrow: 1,
      },
      {
        name: 'Cliente',
        prop: 'customer.complete_name',
        resizeable: false,
        draggable: false,
        flexGrow: 1,
      },
      {
        name: 'Teléfono',
        prop: 'customer.phone',
        resizeable: false,
        draggable: false,
        flexGrow: 1,
      },
      {
        name: 'Fecha',
        prop: 'created_at',
        resizeable: false,
        draggable: false,
        flexGrow: 1,
      },
      {
        name: 'Requiere<br>factura',
        prop: 'requires_invoice',
        resizeable: false,
        draggable: false,
        cellTemplate: this.billing,
        flexGrow: 1,
      },
      {
        name: 'Factura<br>Enviada',
        prop: 'invoice_generated',
        resizeable: false,
        draggable: false,
        sortable: false,
        cellTemplate: this.billSended,
        flexGrow: 1,
      },
      {
        name: 'Empleado',
        prop: 'employee.complete_name',
        resizeable: false,
        draggable: false,
        cellTemplate: this.employee,
        flexGrow: 1,
      },
    ];
  };

  setStatusColor(estatus: string) {
    let cssClass: string = '';
    switch (estatus) {
      case OrderStatus.COMPLETED:
      case PaymentStatus.PAID:
        cssClass = 'success';
        break;
      case OrderStatus.NEW:
        cssClass = 'info';
        break;
      case OrderStatus.CANCELED:
      case PaymentStatus.WITHOUT_PAYMENT:
        cssClass = 'danger';
        break;
      case OrderStatus.IN_PROGRESS:
      case PaymentStatus.PENDING_PAYMENT:
        cssClass = 'warning';
        break;
    }
    return cssClass;
  }

  public setPage = async (pageInfo: any): Promise<any> => {
    this.filters.page = pageInfo.offset;
    this.getOrders();
  };

  public filter() {}

  public resetFilters() {
    this.temporaryFilters.size = 15;
    this.temporaryFilters.page = 0;
    this.temporaryFilters.statusDone = false;
    this.temporaryFilters.statusNew = false;
    this.temporaryFilters.statusCanceled = false;
    this.temporaryFilters.statusInProgress = false;
    this.temporaryFilters.phone = '';
    this.temporaryFilters.number = '';
    this.temporaryFilters.payment = 'all';
    this.temporaryFilters.complete_name = '';
    this.temporaryFilters.paymentStatus = 'all';
    this.temporaryFilters.municipality = '';
    this.temporaryFilters.userAdd = '';
    this.estatus = 'all';
  }

  public resetDates() {
    this.temporaryFilters.endDate = undefined;
    this.temporaryFilters.startDate = undefined;
  }

  filterOrders() {
    if (
      this.temporaryFilters.statusCanceled &&
      this.temporaryFilters.statusInProgress &&
      this.temporaryFilters.statusNew
    ) {
      this.temporaryFilters.statusCanceled = false;
      this.temporaryFilters.statusInProgress = false;
      this.temporaryFilters.statusNew = false;
    }

    this.filters = { ...this.temporaryFilters, page: 0 };
    this.getOrders();
  }

  getAddress = ({
    street,
    number,
    colony,
    location,
    state,
    postal_code,
    number_in,
  }: Address): string => {
    return `${street} #${number},${
      number_in ? ' ' + number_in + ',' : ' '
    } Col. ${colony}, ${location}, ${postal_code}, ${state}`;
  };

  openSetFolioModal(order: any) {
    this.openModal(this.setFolio, 'modal-rounded', order);
  }

  async openSetAmountModal() {
    await this.getOrders();
    if (
      this.selectedOrder.type_product === ProductType.CILINDER &&
      this.selectedOrder.payment === PaymentType.ONLINE
    ) {
      await this.openModal(this.amount, 'modal-rounded', this.selectedOrder);
      return;
    }
    this.openSetCommentsModal();
  }

  openSetOrderStatusModal(order: any) {
    this.openModal(this.setOrderStatus, 'modal-rounded', order);
  }

  openSetPaymentStatusModal(order: any) {
    this.openModal(this.setPaymentStatus, 'modal-rounded', order);
  }

  async openSetCommentsModal() {
    await this.getOrders();
    let order = this.orderList.filter(
      (order) => order.id === this.selectedOrder.id
    )[0];
    this.openModal(this.setComments, 'modal-rounded', order);
  }

  openDetailsModal(order: any) {
    this.openModal(this.details, 'modal-lg modal-rounded', order);
  }

  openUploadBillModal(order: any) {
    this.openModal(this.bill, 'modal-rounded', order);
  }

  async openModal(
    modalContent: any,
    modalClass: string,
    order?: any,
    shouldRefreshOrders?: boolean
  ) {
    if (order) {
      this.selectedOrder = { ...order };
    }

    if (shouldRefreshOrders) {
      await this.getOrders();
    }

    this.modalRef = this.modalService.openModal(modalContent, modalClass);
  }

  setEstatus() {
    switch (this.estatus) {
      case OrderStatus.CANCELED:
        this.temporaryFilters.statusCanceled = true;
        this.temporaryFilters.statusDone = false;
        this.temporaryFilters.statusInProgress = false;
        this.temporaryFilters.statusNew = false;
        break;
      case OrderStatus.COMPLETED:
        this.temporaryFilters.statusCanceled = false;
        this.temporaryFilters.statusDone = true;
        this.temporaryFilters.statusInProgress = false;
        this.temporaryFilters.statusNew = false;
        break;
      case OrderStatus.IN_PROGRESS:
        this.temporaryFilters.statusCanceled = false;
        this.temporaryFilters.statusDone = false;
        this.temporaryFilters.statusInProgress = true;
        this.temporaryFilters.statusNew = false;
        break;
      case OrderStatus.NEW:
        this.temporaryFilters.statusCanceled = false;
        this.temporaryFilters.statusDone = false;
        this.temporaryFilters.statusInProgress = false;
        this.temporaryFilters.statusNew = true;
        break;
      default:
        this.temporaryFilters.statusCanceled = false;
        this.temporaryFilters.statusDone = false;
        this.temporaryFilters.statusInProgress = false;
        this.temporaryFilters.statusNew = false;
        break;
    }
  }

  onSort(event: any) {
    this.table.offset = this.filters.page;
  }
}

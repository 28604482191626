<div class="modal-header d-flex justify-content-center border-0">
  <h4 class="modal-title text-color fw-bold mt-3">Agregar folio</h4>
</div>
<div class="modal-body text-center pt-0">
  <form [formGroup]="folioForm" class="w-100 pt-2" (ngSubmit)="onSubmit()">
    <div
      class="container d-flex flex-column justify-content-center align-items-center"
    >
      <div class="form-group w-75 me-1">
        <div class="form-floating">
          <input
            id="number"
            type="text"
            class="form-control"
            placeholder="Número de folio"
            formControlName="number"
            (input)="removeInitialSpaces()"
          />
          <label for="number">Número de folio</label>
        </div>
        <div class="text-danger pt-1">
          {{ folioErrors }}
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <button
        class="btn btn-primary bg-ligth-mode ps-5 mt-5 pt-2 pb-2 pe-5"
        [disabled]="folioForm.invalid || submitted"
        type="submit"
      >
        Continuar
      </button>
    </div>
    <label class="clickable text-ligth-mode fw-bolder mt-3" (click)="close()">
      Cancelar
    </label>
  </form>
</div>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ENDPOINT } from '@constants';
import { ExportType } from '@enum';
import { OrdersFilters } from '@interfaces';
import { OrderListItem } from 'app/core/interfaces/order-list-item.interface';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  constructor(private http: HttpClient) {}

  filtered({
    size,
    page,
    statusNew,
    statusCanceled,
    statusInProgress,
    statusDone,
    endDate,
    number,
    payment,
    phone,
    requiresInvoice,
    startDate,
    userAdd,
    userId,
    paymentStatus,
    municipality,
    complete_name,
  }: OrdersFilters): Observable<any> {
    return this.http
      .get<any>(
        ENDPOINT.ORDERS.PAGINATED(
          size,
          page,
          statusNew,
          statusCanceled,
          statusInProgress,
          statusDone,
          number,
          userId,
          userAdd,
          startDate,
          endDate,
          payment,
          phone,
          requiresInvoice,
          paymentStatus,
          municipality,
          complete_name
        )
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  updateOrder(order: OrderListItem): Observable<any> {
    return this.http
      .put<any>(ENDPOINT.ORDERS.UPDATE_ORDER(order.id), order)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  export(format: ExportType, filters: any): Observable<any> {
    return this.http.get<any>(ENDPOINT.ORDERS.EXPORT(format, filters)).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  uploadBill(
    pdf: File,
    xml: File,
    orderId: string,
    url: string
  ): Observable<any> {
    const formData = new FormData();
    formData.append('pdf', pdf, pdf.name);
    formData.append('xml', xml, xml.name);
    formData.append('order_id', orderId);
    formData.append('url', url);
    return this.http.post<any>(ENDPOINT.ORDERS.UPLOAD_BILL, formData).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  sendBillNotification(orderId: number, url: string): Observable<any> {
    const formData = new FormData();
    formData.append('order_id', orderId + '');
    formData.append('url', url);
    return this.http.post<any>(ENDPOINT.ORDERS.UPLOAD_BILL, formData).pipe(
      map((response: any) => {
        return response;
      })
    );
  }
}

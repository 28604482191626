import { Component, Input } from '@angular/core';
import { ExportModules, ExportType } from '@enum';
import { FileService } from 'app/core/services/file.service';
import { PricingService } from 'app/features/pricing/services/pricing.service';
import { UserService } from 'app/features/users/services/user.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-export-buttons',
  templateUrl: './export-buttons.component.html',
  styleUrl: './export-buttons.component.scss',
})
export class ExportButtonsComponent {
  @Input() module: ExportModules = ExportModules.PRICING;
  @Input() type?: string = '';
  exportType = ExportType;

  private exportServices: { [key: string]: any } = {};

  constructor(
    private pricingService: PricingService,
    private userService: UserService,
    private toastr: ToastrService,
    private fileService: FileService
  ) {
    this.exportServices[ExportModules.PRICING] = this.pricingService;
    this.exportServices[ExportModules.USERS] = this.userService;
  }

  public export = async (format: ExportType): Promise<any> => {
    const service = this.exportServices[this.module];

    service.export(format, this.type).subscribe(({ code, data }: any) => {
      if (code === 500) {
        return this.toastr.error('No se pudo generar el archivo', '¡Error!');
      }
      this.toastr.success('¡Archivo descargado!', '¡Éxito!');
      this.fileService.downloadFile(data.url, data.name);
      return;
    });
  };
}
